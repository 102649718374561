import { useSelector, useDispatch } from 'react-redux';
import { setLoading } from 'app/redux/reducer/loaderSlice';

export function useLoading() {
  const dispatch = useDispatch();
  const isProcessing = useSelector(state => state.loading.isLoading); // Access loading state

  // Function to update the loading state
  const toggleLoading = (loadingState) => {
    dispatch(setLoading(loadingState));
  };

  return { isProcessing, toggleLoading };
}
