import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

function EditAppointmentModel(
  { show, handleClose, serviceList,checkoutDetails, checkoutprice,  handleCheckboxChange },
  props
) {
 
  return (
    <Modal
      show={show}
      {...props}
      aria-hidden="true"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
      dialogClassName="custom__modal"
      backdropClassName="modal-backdrop fade"
      style={{ height: "80%" }}
    >
      <Modal.Header className="modal-header modal-header1">
        <h1 className="modal-title fs-5" id="exampleModalToggleLabel2">
          Appointment Edit
        </h1>
      </Modal.Header>
      <Modal.Body className="modal-body p-0 text-start ps-3 pt-3 p-5" style={{ overflowX:"hidden" }}>
      <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            minWidth: "657px",
            height: "518px",
            overflow: "scroll",
          }}
        >
          <h5 className="text-center service_list_resposive" style={{ marginTop:"110`px" }}>
            <b>Service List</b>
          </h5>
          {/* <br /> */}
          <hr />
          <Typography id="modal-modal-title" variant="h6" component="h2" className="new_modal_title">
            <h5 className="service_name">
              <b>
                Service Name <span className="service_price">Price</span>
              </b>
            </h5>

            <form action="/">
              {
                serviceList != null
                  ? serviceList.map((service, index) => (
                      <div key={index}>
                        <input
                          type="checkbox"
                          id={`vehicle${index}`}
                          value={service._id}
                          onChange={() => handleCheckboxChange(service._id)}
                          checked={
                            checkoutDetails &&
                            checkoutDetails.includes(service._id)
                          }
                        />
                        &nbsp;{" "}
                        <label className="z-query-width">
                          <p>
                            {" "}
                            {service.serviceName}{" "}
                            <span className="spann_right">
                              {service.price}{" "}
                            </span>
                          </p>
                        </label>
                      </div>
                    ))
                  : null /* You should handle the case where serviceList is null or empty */
              }

              <hr />

              <div>
                <label className="z-query-width" style={{ width: "98%" }}>
                  <p>
                    <b>Total</b>
                    <span className="spann_right">
                      <b>{checkoutprice}</b>
                    </span>
                  </p>
                </label>
              </div>
            </form>
            <center>
              <button
                className=" ask_for_add_review3  mt-2"
                style={{ padding:"12px 20px"  }}
                onClick={handleClose}
              >
                Cancel
              </button>{" "}
              &nbsp;
              <button
                className="button_a1 mt-2"
                onClick={handleClose}
              >
                Update Appointment
              </button>
            </center>
          </Typography>
        </Box>
      </Modal.Body>
    </Modal>
  );
}

export default EditAppointmentModel;
