import { Box, Typography } from "@mui/material";
import React from "react";
import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

function Rescheduled({ show, handleClose,appointmentItem, selectedTime, selectedDate, setSelectedTime, setSelectedDate, handleDateChange, handleTimeChange, RescheduleAppointmentFun }, props) {


  return (
    <Modal
      show={show}
      {...props}
      aria-hidden="true"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
      dialogClassName="custom__modal"
      backdropClassName="modal-backdrop fade"
      style={{ height: "70%" }}
    >
      <Modal.Header className="modal-header modal-header1">
        <h1 className="modal-title fs-5" id="exampleModalToggleLabel2">
        Reschedule Appointment
        </h1>
        <button type="button" className="btn-close" onClick={handleClose} />
      </Modal.Header>
      <Modal.Body className="modal-body p-0 text-start ps-3 pt-3">
      <div>
                <div className="mb-3">
                  <div className="row">
                    <div className="col-lg-6">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="form-label form123 text-start label_date"
                      >
                        Date
                      </label>
                      <input
                        type="date"
                        className="form-select"
                        onChange={handleDateChange}
                        value={selectedDate}
                        min={new Date().toISOString().split('T')[0]} // Set min to today's date
                      
                      />
                    </div>
                    <div className="col-lg-6">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="form-label form123 text-start label_date"
                      >
                        Time
                      </label>
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        value={selectedTime}
                        onChange={handleTimeChange}
                      >
                        <option value="0:00 AM">12:00 AM</option>
                        <option value="0:30 AM">12:30 AM</option>
                        <option value="1:00 AM">1:00 AM</option>
                        <option value="1:30 AM">1:30 AM</option>
                        <option value="2:00 AM">2:00 AM</option>
                        <option value="2:30 AM">2:30 AM</option>
                        <option value="3:00 AM">3:00 AM</option>
                        <option value="3:30 AM">3:30 AM</option>
                        <option value="4:00 AM">4:00 AM</option>
                        <option value="4:30 AM">4:30 AM</option>
                        <option value="5:00 AM">5:00 AM</option>
                        <option value="5:30 AM">5:30 AM</option>
                        <option value="6:00 AM">6:00 AM</option>
                        <option value="6:30 AM">6:30 AM</option>
                        <option value="7:00 AM">7:00 AM</option>
                        <option value="7:30 AM">7:30 AM</option>
                        <option value="8:00 AM">8:00 AM</option>
                        <option value="8:30 AM">8:30 AM</option>
                        <option value="9:00 AM">9:00 AM</option>
                        <option value="9:30 AM">9:30 AM</option>
                        <option value="10:00 AM">10:00 AM</option>
                        <option value="10:30 AM">10:30 AM</option>
                        <option value="11:00 AM">11:00 AM</option>
                        <option value="11:30 AM">11:30 AM</option>
                        <option value="12:00 PM">12:00 PM</option>
                        <option value="12:30 PM">12:30 PM</option>
                        <option value="1:00 PM">1:00 PM</option>
                        <option value="1:30 PM">1:30 PM</option>
                        <option value="2:00 PM">2:00 PM</option>
                        <option value="2:30 PM">2:30 PM</option>
                        <option value="3:00 PM">3:00 PM</option>
                        <option value="3:30 PM">3:30 PM</option>
                        <option value="4:00 PM">4:00 PM</option>
                        <option value="4:30 PM">4:30 PM</option>
                        <option value="5:00 PM">5:00 PM</option>
                        <option value="5:30 PM">5:30 PM</option>
                        <option value="6:00 PM">6:00 PM</option>
                        <option value="6:30 PM">6:30 PM</option>
                        <option value="7:00 PM">7:00 PM</option>
                        <option value="7:30 PM">7:30 PM</option>
                        <option value="8:00 PM">8:00 PM</option>
                        <option value="8:30 PM">8:30 PM</option>
                        <option value="9:00 PM">9:00 PM</option>
                        <option value="9:30 PM">9:30 PM</option>
                        <option value="10:00 PM">10:00 PM</option>
                        <option value="10:30 PM">10:30 PM</option>
                        <option value="11:00 PM">11:00 PM</option>
                        <option value="11:30 PM">11:30 PM</option>
                      </select>
                    </div>
                  </div>
                </div>
                <button
                  className="add_card_btn"
                  onClick={() => {
                    RescheduleAppointmentFun(
                        appointmentItem._id
                    );
                    // handleCloseModal2();
                  }}
                >
                  Reschedule Appointment
                </button>
              </div>
      </Modal.Body>
    </Modal>
  );
}

export default Rescheduled;
