import { Helmet } from 'react-helmet';

function SEO(props) {
  return (
      <Helmet>
        <title>{props.title}</title>
        <meta name="title" content={props.title} />
        <meta name="description" content={props.content} />
        <meta name="keywords" content={props.keywords} />
        <meta property="og:image" content={props.image} />
      </Helmet>
  );
}

export default SEO