import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import AuthGuard from './auth/AuthGuard';
import { authRoles } from './auth/authRoles';
import Loadable from './components/Loadable';
import MatxLayout from './components/MatxLayout/MatxLayout';
import AppointmentsForBusiness from './site/profile/AppointmentsForBusiness';
import TotalEarning from './site/totalEarning/TotalEarning';
import LiveChat from './site/LiveChat/LiveChat';
import ForgetChangePassword from './site/siteAuth/ForgetChangePassword';
import getUserRole from './utils/getUserRole';

// import { MyAppointments } from './site/profile/MyAppointments';

//  ============== ADMIN ROUTES START ================================================================
  // ------------------Users ---------------
  const CustomerList = Loadable(lazy(() => import('./views/customers/CustomerList')));
  const BusinessUser = Loadable(lazy(() => import('./views/customers/BusinessUser')));
  const User = Loadable(lazy(() => import('./views/customers/User')));
  const NewCustomer = Loadable(lazy(() => import('./views/customers/NewCustomer')));
  const CustomerDetails = Loadable(lazy(() => import('./views/customers/CustomerDetails')));
  // ---------- Service ---------------------
  const ServersList = Loadable(lazy(() => import('./views/services/ServicesList')));
  const ServiceDetails = Loadable(lazy(() => import('./views/services/ServiceDetails')));
  // -----------Product -----------------------
  const ProductList = Loadable(lazy(() => import('./views/products/ProductList')));
  const ProductDetails = Loadable(lazy(() => import('./views/products/ProductDetails')));
  // ---------- Banner ------------------------
  const BannerList = Loadable(lazy(() => import('./views/banner/BannerList')));
  const AddBanner = Loadable(lazy(() => import('./views/banner/AddBanner')));
  const EditBanner = Loadable(lazy(() => import('./views/banner/EditBanner')));
  // ------------- Category ---------------------
  const CategoryList = Loadable(lazy(() => import('./views/categories/CategoryList')));
  const NewCategory = Loadable(lazy(() => import('./views/categories/AddCategory')));
  const EditCategory = Loadable(lazy(() => import('./views/categories/EditCategory')));
  // --------Sub Category --------------------
  const SubCategory = Loadable(lazy(() => import('./views/subCategory/SubCategoryList')));
  const AddSubCategory = Loadable(lazy(() => import('./views/subCategory/AddSubCategory')));
  const EditSubCategory = Loadable(lazy(() => import('./views/subCategory/EditSubCategory')));
  // ------------ Plan ------------------------
  const PlanList = Loadable(lazy(() => import('./views/plan/PlanList')));
  const AddPlan = Loadable(lazy(() => import('./views/plan/AddPlan')));
  const EditPlan = Loadable(lazy(() => import('./views/plan/EditPlan')));
  // ------------ Personalize ------------------------
  const PersonalizeList = Loadable(lazy(() => import('./views/personalize/PersonalizeList')));
  const AddPersonalize = Loadable(lazy(() => import('./views/personalize/AddPersonalize')));
  const EditPersonalize = Loadable(lazy(() => import('./views/personalize/EditPersonalize')));
  // ------------ Personalize ------------------------
  const SpecialtyList = Loadable(lazy(() => import('./views/specialty/SpecialtyList')));
  const AddSpecialty = Loadable(lazy(() => import('./views/specialty/AddSpecialty')));
  const EditSpecialty = Loadable(lazy(() => import('./views/specialty/EditSpecialty')));
  // ------------ Template ------------------------
  const TemplateList = Loadable(lazy(() => import('./views/template/TemplateList')));
  const TemplateAdd = Loadable(lazy(() => import('./views/template/AddTemplate')));
  const TemplateEdit = Loadable(lazy(() => import('./views/template/EditTemplate')));

  // ---------Help --------------------------
  const HelpList = Loadable(lazy(() => import('./views/help/HelpList')));
  const HelpDetails = Loadable(lazy(() => import('./views/help/HelpDetails')));
  const HelpMessage = Loadable(lazy(() => import('./views/help/HalpMessage')));

  // ------- Support ---------------

  const SupportList = Loadable(lazy(() => import('./views/support/SupportList')));



  // ---------- Partner ------------------------
  const PartnerList = Loadable(lazy(() => import('./views/partner/ListPartner')));
  const AddPartner = Loadable(lazy(() => import('./views/partner/AddPartner')));
  const EditPartner = Loadable(lazy(() => import('./views/partner/EditPartner')));
    // ---------- Review ------------------------
    const ReviewList = Loadable(lazy(() => import('./views/review/ListReview')));
    const AddReview = Loadable(lazy(() => import('./views/review/AddReview')));
    const EditReview = Loadable(lazy(() => import('./views/review/EditReview')));
  // ---------------Chat---------------------
  const ChatList = Loadable(lazy(() => import('./views/chat/ChatList')));
  const ChatDetails = Loadable(lazy(() => import('./views/chat/ChatDetails')));

  //------- session pages-------
  const NotFound = Loadable(lazy(() => import('app/views/auth/NotFound')));
  const JwtLogin = Loadable(lazy(() => import('app/views/auth/JwtLogin')));
  const JwtRegister = Loadable(lazy(() => import('app/views/auth/JwtRegister')));
  const ForgotPassword = Loadable(lazy(() => import('app/views/auth/ForgotPassword')));
  // dashboard page
  const Analytics = Loadable(lazy(() => import('app/views/dashboard/Analytics')));
  // user profile
  const UserProfile = Loadable(lazy(() => import('app/views/adminprofile/Profile')));
  // transactions list
  const Transactions = Loadable(lazy(() => import('app/views/transactions/Transactions')));
  const TransactionDetails = Loadable(lazy(() => import('app/views/transactions/TransactionDetails')));
  
  // transactions list
  const Withdrawal = Loadable(lazy(() => import('app/views/withdrawal/Withdrawal')));

  // revenue list
  const Revenue = Loadable(lazy(() => import('app/views/revenue/Revenue')));
  const ProductRevenue = Loadable(lazy(() => import('app/views/revenue/ProductRevenue')));
  const ServiceRevenue = Loadable(lazy(() => import('app/views/revenue/ServiceRevenue')));
  const ServiceRevenueDetails = Loadable(lazy(() => import('app/views/revenue/ServiceRevenueDetails')));
  const ProductRevenueDetails = Loadable(lazy(() => import('app/views/revenue/ProductRevenueDetails')));
  // ------Term & Condition ------------
  const TermAndCondition = Loadable(lazy(() => import('app/views/privacyAndCondition/termAndCondition')));
  const PrivacyPolicy = Loadable(lazy(() => import('app/views/privacyAndCondition/privacyAndPolicy')));

  
// ================== ADMIN ROUTES END =======================================================

// ================== WEBSITE ROUTES START =======================================================

  const Login = Loadable(lazy(() => import('./site/siteAuth/Login')));
  const SignUp = Loadable(lazy(() => import('./site/siteAuth/SignUp')));
  const CustomerSignup = Loadable(lazy(() => import('./site/siteAuth/CustomerSignup')));
  const CreateBusiness = Loadable(lazy(() => import('./site/businessAuth/CreateBusiness')));
  const CreateBusinessAccount = Loadable(lazy(() => import('./site/businessAuth/CreateBusinessAccount')));
  const LoginBusiness = Loadable(lazy(() => import('./site/businessAuth/LoginBusiness')));
  const ForgetPassword = Loadable(lazy(() => import('./site/siteAuth/ForgetPassword')));
  const ResetPassword = Loadable(lazy(() => import('./site/siteAuth/ResetPassword')));
  const Otp = Loadable(lazy(() => import('./site/Otp/Otp')));

  const Home = Loadable(lazy(() => import('./site/Home')));
  const Services = Loadable(lazy(() => import('./site/services/Services')));
  const WebServiceDetails = Loadable(lazy(() => import('./site/services/ServiceDetails')));

  const FindProducts = Loadable(lazy(() => import('./site/products/FindProducts')));

  const AddToCart = Loadable(lazy(() => import('./site/AddToCart/AddToCart')));

  const OrderBuy = Loadable(lazy(() => import('./site/orderbuy/OrderBuy')));

  const Shipping = Loadable(lazy(() => import('./site/shipping/Shipping')));

  const PaymentMethod = Loadable(lazy(() => import('./site/paymentmethod/PaymentMethod')));


  const BusinessName = Loadable(lazy(() => import('./site/businessprofile/BusinessName')));
  const BusinessLoction = Loadable(lazy(() => import('./site/businessprofile/BusinessLoction')));

  const AfterLoginBusiness = Loadable(lazy(() => import('./site/afterloginbusiness/AfterLoginBusiness')));
  const UpgradeSubscription = Loadable(lazy(() => import('./site/Components/AfterLoginBusiness.jsx/UpgradeSubscription')));



  const MyAccount = Loadable(lazy(() => import('./site/profile/MyAccount')));
  const MyAppointments = Loadable(lazy(() => import('./site/profile/MyAppointments')));
  const MyOrder = Loadable(lazy(() => import('./site/profile/MyOrder')));
  const Favorite = Loadable(lazy(() => import('./site/profile/Favorite')));
  const Loading = Loadable(lazy(() => import('./site/Components/Loader/loading')));

  const PaymentFailed = Loadable(lazy(() => import('./site/paymentmethod/PaymentFailed')));
  const PaymentSuccessful = Loadable(lazy(() => import('./site/paymentmethod/PaymentSuccess')));
  const PaymentSuccessTwo = Loadable(lazy(() => import('./site/paymentmethod/PaymentSuccessTwo')));

  const AppointmentCalander = Loadable(lazy(() => import('./site/siteAuth/AppointmentCalander')));
  const PrivacyPolicyWeb = Loadable(lazy(() => import('./site/privacypolicy/PrivacyPolicy')));

  const Learnmore = Loadable(lazy(() => import('./site/learnMore/LearnMore')));

  const Help = Loadable(lazy(() => import('./site/privacypolicy/Help')));
  const Termscondition = Loadable(lazy(() => import('./site/privacypolicy/Termscondition')));

  const AboutUs = Loadable(lazy(() => import('./site/privacypolicy/AboutUs')));
  
  const WithdrawalPay = Loadable(lazy(() => import('./site/Withdrawal/WithdrawalPay')));

  const Client = Loadable(lazy(() => import('./site/Components/MyAccount/Client')));

  const InviteBook = Loadable(lazy(() => import('./site/siteAuth/InviteBook')));
//Check user role 
 // Assuming you have a function to get the user's role
 const userRole = getUserRole(); // You need to implement this function
 const user = JSON.parse(localStorage.getItem("user"));




// ============ WEBSITE ROUTES ================================================


const routes = [
  {
    element: (
      <AuthGuard>
        <MatxLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/dashboard',
        element: <Analytics />,
        auth: authRoles.admin
      },
      {
        path: 'admin/profile',
        element: <UserProfile />,
        auth: authRoles.admin
      },
      {
        path: '/customer/list',
        element: <CustomerList />,
        auth: authRoles.admin
      },
      {
        path: '/business/list',
        element: <BusinessUser />,
        auth: authRoles.admin
      },
      {
        path: '/user/list',
        element: <User />,
        auth: authRoles.admin
      },
      {
        path: '/customer/details/:id',
        element: <CustomerDetails />,
        auth: authRoles.admin
      },
      {
        path: '/services/list',
        element: <ServersList />,
        auth: authRoles.admin
      },
      {
        path: '/services/details/:id',
        element: <ServiceDetails />,
        auth: authRoles.admin
      },
      {
        path: '/transactions/details/:id',
        element: <TransactionDetails />,
        auth: authRoles.admin
      },
      {
        path: '/withdrawal/request',
        element: <Withdrawal />,
        auth: authRoles.admin
      },
      {
        path: '/product/list',
        element: <ProductList />,
        auth: authRoles.admin
      },
      {
        path: '/product/details/:id',
        element: <ProductDetails />,
        auth: authRoles.admin
      },
      {
        path: '/banner/list',
        element: <BannerList />,
        auth: authRoles.admin
      },
      {
        path: '/banner/new',
        element: <AddBanner />,
        auth: authRoles.admin
      },
      {
        path: '/banner/edit/:id',
        element: <EditBanner />,
        auth: authRoles.admin
      },
      {
        path: '/categories/list',
        element: <CategoryList />,
        auth: authRoles.admin
      },
      {
        path: '/categories/new',
        element: <NewCategory />,
        auth: authRoles.admin
      },
      {
        path: '/categories/edit/:id',
        element: <EditCategory />,
        auth: authRoles.admin
      },
      {
        path: '/subCategory/list',
        element: <SubCategory />,
        auth: authRoles.admin
      },
      {
        path: '/subCategory/new',
        element: <AddSubCategory />,
        auth: authRoles.admin
      },
      {
        path: '/subCategory/edit/:id',
        element: <EditSubCategory />,
        auth: authRoles.admin
      },
      
      {
        path: '/plan/list',
        element: <PlanList />,
        auth: authRoles.admin
      },
      {
        path: '/plan/new',
        element: <AddPlan/>,
        auth: authRoles.admin
      },
      {
        path: '/plan/edit/:id',
        element: <EditPlan/>,
        auth: authRoles.admin
      },
      {
        path: '/help/list',
        element: <HelpList/>,
        auth: authRoles.admin
      },
      {
        path: '/services/personalize/list',
        element: <PersonalizeList />,
        auth: authRoles.admin
      },
      {
        path: '/services/personalize/new',
        element: <AddPersonalize/>,
        auth: authRoles.admin
      },
      {
        path: '/services/personalize/edit/:id',
        element: <EditPersonalize/>,
        auth: authRoles.admin
      },
      {
        path: '/services/specialty/list',
        element: <SpecialtyList />,
        auth: authRoles.admin
      },
      {
        path: '/services/specialty/new',
        element: <AddSpecialty/>,
        auth: authRoles.admin
      },
      {
        path: '/services/specialty/edit/:id',
        element: <EditSpecialty/>,
        auth: authRoles.admin
      },
      {
        path: '/services/template/list',
        element: <TemplateList/>,
        auth: authRoles.admin
      },
      {
        path: '/services/template/new',
        element: <TemplateAdd/>,
        auth: authRoles.admin
      },
      {
        path: '/services/template/edit/:id',
        element: <TemplateEdit/>,
        auth: authRoles.admin
      },
      {
        path: '/help/list',
        element: <HelpList/>,
        auth: authRoles.admin
      },
      {
        path: '/help/details/:id',
        element: <HelpDetails/>,
        auth: authRoles.admin
      },
      {
        path: '/help/message/:id',
        element: <HelpMessage/>,
        auth: authRoles.admin
      },      
      {
        path: '/partner/list',
        element: <PartnerList />,
        auth: authRoles.admin
      },
      {
        path: '/partner/new',
        element: <AddPartner />,
        auth: authRoles.admin
      },
      {
        path: '/partner/edit/:id',
        element: <EditPartner />,
        auth: authRoles.admin
      },
      {
        path: '/review/list',
        element: <ReviewList />,
        auth: authRoles.admin
      },
      {
        path: '/review/new',
        element: <AddReview />,
        auth: authRoles.admin
      },
      {
        path: '/review/edit/:id',
        element: <EditReview />,
        auth: authRoles.admin
      },
      {
        path: '/transactions',
        element: <Transactions />,
        auth: authRoles.admin
      },
      {
        path: '/total_revenue',
        element: <Revenue />,
        auth: authRoles.admin
      },
      {
        path: '/product_revenue',
        element: <ProductRevenue />,
        auth: authRoles.admin
      },
      {
        path: '/service_revenue',
        element: <ServiceRevenue />,
        auth: authRoles.admin
      },
      {
        path: '/service_revenue/details/:id',
        element: <ServiceRevenueDetails/>,
        auth: authRoles.admin
      },
      {
        path: '/product_revenue/details/:id',
        element: <ProductRevenueDetails/>,
        auth: authRoles.admin
      },
      {
        path: '/chat/list',
        element: <ChatList />,
        auth: authRoles.admin
      },
      {
        path: '/chat/details/:id',
        element: <ChatDetails />,
        auth: authRoles.admin
      },
      {
        path: '/termAndCondition/',
        element: <TermAndCondition />,
        auth: authRoles.admin
      },
      {
        path: '/privacy-Policy/',
        element: <PrivacyPolicy />,
        auth: authRoles.admin
      },
      {
        path: '/support/list',
        element: <SupportList />,
        auth: authRoles.admin
      },
      
    ]
  },
  // session pages route
  { path: '/page/404', element: <NotFound /> },
  { path: '/admin/login', element: <JwtLogin /> },
  { path: '/auth/signup', element: <JwtRegister /> },
  { path: '/auth/forgot-password', element: <ForgotPassword /> },
  { path: '/loading', element: <Loading /> },

  { path: '/user/login', element: <Login /> },
  { path: '/user/signup', element: <SignUp /> },
  { path: '/user/create_account', element: <CustomerSignup /> },
  { path: '/business/create_business', element: <CreateBusiness /> },
  { path: '/business/create_business_account', element: <CreateBusinessAccount /> },
  { path: '/business/login_business', element: <LoginBusiness /> },
  { path: '/user/forget_password', element: <ForgetPassword /> },
  { path: '/user/reset_password', element: <ResetPassword /> },
  { path: '/user/otp', element: <Otp /> },
  { path: '/', element: user && userRole === 'business' ? <AfterLoginBusiness /> : <Home /> },

  { path: '/services_list', element: <Services /> },
  { path: '/services_details/:name/:id', element: <WebServiceDetails /> },

  { path: '/find_products', element: <FindProducts /> },
  { path: '/add_to_cart', element: <AddToCart /> },
  { path: '/order_buy', element: <OrderBuy /> },
  { path: '/shipping', element: <Shipping /> },
  { path: '/payment_method', element: <PaymentMethod /> },
  { path: '/business_name', element: <BusinessName /> },
  { path: '/business_loction', element: <BusinessLoction /> },
  { path: '/business_setup', element: <AfterLoginBusiness /> },
  { path: '/Upgrade_Subscription', element: <UpgradeSubscription /> },
 

  { path: '/payment-failed/', element: <PaymentFailed /> },

  
  { path: '/payment-Successful/', element: <PaymentSuccessful /> },
  { path: '/payment-Successful/appointments', element: <PaymentSuccessTwo /> },
  { path: '/my_account', element: <MyAccount />, auth: authRoles.user },
  { path: '/my_appointments', element: <AppointmentsForBusiness /> },
  { path: '/appointment_calander', element: <AppointmentCalander /> },
  { path: '/appointments', element: <MyAppointments /> },
  { path: '/my_order', element: <MyOrder /> },
  { path: '/favorite', element: <Favorite /> },
  { path: '/earning', element: <TotalEarning /> },
  { path: '/live_chat', element: <LiveChat /> },
  { path: '/new_password', element: <ForgetChangePassword /> },
  { path: '/privacypolicy', element: <PrivacyPolicyWeb /> },
  { path: '/learnmore', element: <Learnmore /> },
  { path: '/help', element: <Help /> },
  { path: '/terms_condition', element: <Termscondition /> },
  { path: '/about_us', element: <AboutUs /> },
  { path: '/withdrawal', element: <WithdrawalPay /> },
  { path: '/client', element: <Client /> },
  { path: '/appointment/:id', element: <InviteBook /> },

  // { path: '/', element: <Navigate to="dashboard" /> },
  { path: '*', element: <NotFound /> }
];

export default routes;
