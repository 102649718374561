import React from "react";
import Vector from "../assets/images/Vector.png";
import Vector2 from "../assets/images/vector2.png";
import Vector3 from "../assets/images/vector3.png";
import Vector4 from "../assets/images/vector4.png";
import logoBlack from "../assets/images/logo_black.png";
import googlePlay from "../assets/images/google_play.png";
import appStore from "../assets/images/app_store.png";
import { Link } from "react-router-dom";
import { GetAPI } from "app/middleware";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

function Footer(props) {
  const [servicelist, setservicelist] = useState([]);
  const [searchlist, setSearchlist] = useState([]);
  const [searchInput, setSearchInput] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [selectCity, setSelectCity] = useState("");
  const navigate = useNavigate();

  const userDetails = props.user

  const Servicelist = async () => {
    const respData = await GetAPI(
      `service/getServiceSearch?search=&city=&date=&profession=`
    );

    const newData = respData.data?.services;
    setservicelist(newData);
  };

  useEffect(() => {
    Servicelist();
  }, []);

  function ramdomDataArray(array, count) {
    let shuffledArray = array.slice(); // Create a shallow copy of the array
    
    // Shuffle the array
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    
    // Return a slice of the shuffled array with the specified count
    return shuffledArray.slice(0, count);
  }

  const searchServiceFun = async () => {
    // api call
    const respData = await GetAPI(userDetails ?
      `service/getServiceSearch?search=${searchValue}&city=${selectCity}&date=&profession=&userId=${userDetails._id}` :
      `service/getServiceSearch?search=${searchValue}&city=${selectCity}&date=&profession=`
    );
    navigate("/services_list", {
      state: {
        itemlist: respData.data,
        selectCity: selectCity,
        searchValue: searchValue,
      },
    });
  
  };

  return (
    <footer>
      <div className="container-fluid footer_up pb-3">
        <div className="container">
          <div className="row">
            {/* <div className="col-lg-3 col-md-6 col-sm-12">
            <div className="row">
              <div className="col-3 bg-white p-4 m-2">
                <img src={Vector} alt="" width="40px" />
              </div>
              <div className="col-8">
                <h5 className="free_returns pt-4">Free Returns</h5>
                <p className="asked_returns">No question asked return policy</p>
              </div>
            </div>
          </div> */}
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="row">
                <div className="col-3 bg-white p-4 m-2">
                  <img src={Vector2} alt="" width="40px" />
                </div>
                <div className="col-8">
                  <h5 className="free_returns pt-4">Money Guarantee</h5>
                  <p className="asked_returns">30 day money back</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="row">
                <div className="col-3 bg-white p-4 m-2">
                  <img src={Vector3} alt="" width="40px" />
                </div>
                <div className="col-8">
                  <h5 className="free_returns pt-4">24/7 Support</h5>
                  <p className="asked_returns">Friendly 24/7 support</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="row">
                <div className="col-3 bg-white p-4 m-2">
                  <img src={Vector4} alt="" width="40px" />
                </div>
                <div className="col-8">
                  <h5 className="free_returns pt-4">Secure Payment</h5>
                  <p className="asked_returns">All Cards accepted</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer_down pb-5">
        <div className="container-fluid">
          <div className="container pt-5">
            <div className="row">
              <div className="col-lg-5">
                <div className="row">
                  <div className="col-md-5">
                    <a href="#">
                      <img src={logoBlack} alt="" width="200px" />
                    </a>
                  </div>
                  <div className="col-md-7">
                    <img
                      src={appStore}
                      alt=""
                      width="150px"
                      className="mt-5 footer_add_margin"
                    />
                    <img src={googlePlay} alt="" width="150px" />
                  </div>
                </div>
                <p className="footer_lorem">
                  Discover the ultimate salon finder platform that connects you
                  to top-notch salons nearby. Find expert stylists, book
                  appointments seamlessly, and unlock exclusive deals. Beautify
                  with ease!
                </p>
              </div>
              <div className="col-lg-7">
                <div className="row">
                  <div className="col-lg-4 about_ft">
                    <h5 className="about_h5 px-4">Menu</h5>
                    <ul className="about_us_ul">
                      <li>
                        <Link
                          to={"/"}
                          className="link_decorations_white"
                        >
                          Home
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={"/about_us"}
                          className="link_decorations_white"
                        >
                          About Us
                        </Link>{" "}
                      </li>
                      <li>
                        <Link
                          to={"/privacypolicy"}
                          className="link_decorations_white"
                        >
                          Privacy Policy
                        </Link>{" "}
                      </li>
                      <li>
                        <Link
                          to={"/terms_condition"}
                          className="link_decorations_white"
                        >
                          Terms and Condition
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-3 about_ft">
                    <h5 className="about_h5 px-4">Services</h5>
                    {/* <ul className="about_us_ul">
                      {ramdomDataArray(servicelist, 3).map((item, index) => (
                        <a
                          href={`/services_details/${item?.user?.name}/${item?.user?._id}`}
                          style={{ textDecoration: "none", color: "#747474" }}
                        >
                          <li>{item.serviceName}</li>
                        </a>
                      ))}
                    </ul> */}

                <ul className="about_us_ul">
                      <a  onClick={searchServiceFun} style={{textDecoration:"none", cursor:"pointer"}}>
                        <li>Braider</li>
                        <li>Stylist</li>
                        <li>Barber</li>
                        <li>Weaves</li>
                        <li>Mua</li>
                        <li>Esthtician</li>
                        <li>Nail Tech</li>
                        <li>Kids</li>
                        <li>Massage</li>
                        <li>Natural Hair</li>
                      </a>
                    </ul>
                  </div>
                  <div className="col-lg-5 about_ft">
                    <h5 className="about_h5 px-4">Contact Us</h5>
                    <ul className="about_us_ul">
                      <li>Shop one stop </li>
                      <li>
                        Address : 2274 Salem Rd SE Ste 106 #5224 Conyers Ga
                        30013
                      </li>
                      <li>Phone : 4702028980</li>
                      <li>Email: admin@ShopOneStop.net</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <hr className="text-light" />
            <div className="row">
              <div className="col-lg-6 ">
                <p className="copy_right">
                  © All Copyright {new Date().getFullYear()} | shoponestop.net
                </p>
              </div>
              <div className="col-lg-6">
                <ul className="footer_nd_list">
                  <li>
                    <div className="reduis_angle" style={{ cursor: "pointer" }}>
                      <a href="https://www.facebook.com/ShopOneStopltd" target="_blank">
                        {" "}
                        <i className="fa-brands fa-facebook-f " />
                      </a>
                    </div>
                  </li>
                  {/* <li>
                    <div className="reduis_angle" style={{ cursor: "pointer" }}>
                      <a href="https://twitter.com/" target="_blank">
                        {" "}
                        <i className="fa-brands fa-twitter" />
                      </a>
                    </div>
                  </li> */}
                  <li>
                    <div className="reduis_angle" style={{ cursor: "pointer" }}>
                      <a href="https://www.instagram.com/shop1stopltd?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==" target="_blank">
                        <i className="fa-brands fa-instagram" />
                      </a>
                    </div>
                  </li>
                  {/* <li>
                    <div className="reduis_angle" style={{ cursor: "pointer" }}>
                      <a href="https://www.pinterest.com/" target="_blank">
                        {" "}
                        <i className="fa-brands fa-pinterest" />
                      </a>
                    </div>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
export default Footer;
