/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import tingrii from "../../assets/images/dummy-image.jpg";
import Footer from "../Footer";
import MyAppointmentNavbar from "../siteAuth/MyAppointmentNavbar";
import { GetAdminAPI, PutAdminAPI, PostAdminAPI } from "app/middleware";
import { useEffect } from "react";
import { useState } from "react";

import { toast } from "react-toastify";
import { Rating } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import CancelModelBusiness from "app/components/Modals/Services/CancelModelBusiness";
import { Link } from "react-router-dom";
import Loading from "app/components/Loading";
import ViewMoreService from "app/components/Modals/Services/ViewMoreService";
import { Modal } from "react-bootstrap";
import NoShowModel from "app/components/Modals/Services/NoShowModel";
import Rescheduled from "app/components/Modals/Services/Rescheduled";
import CheckoutModel from "app/components/Modals/Services/CheckoutModel";
import AppointmentDetailsModel from "app/components/Modals/Services/AppointmentDetailsModel";
import EditAppointmentModel from "app/components/Modals/Services/EditAppointmentModel";
import SelectPaymentMethod from "app/components/Modals/Services/SelectPaymentMethod";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useLoading } from "app/hooks/useLoader";

const AppointmentsForBusiness = () => {
  const userDetails = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();

  useEffect(() => {
    // Check userDetails on page load or reload
    if (userDetails === null) {
      navigate('/user/login');
    }
  }, [navigate, userDetails]);


  const userToken = localStorage.getItem("token");
  const [appointments, setAppointments] = useState([]);

  const [status, setStatus] = useState(1);
  const [showCencelModal, setShowCancelModal] = useState(false);
  const handleCloseCancel = () => setShowCancelModal(false);

  const [showMoreServiceModal, setshowMoreServiceModal] = useState(false);
  const handleCloseMoreService = () => setshowMoreServiceModal(false);
  const [openAppointmentUpdate, setOpenAppointmentUpdate] = useState(false);
  const handelClickAppointMentUpdate = async () => {
    setOpenAppointmentUpdate(false);
  };
  const { toggleLoading } = useLoading();
  const [serviceList, setServiceList] = useState([]);
  const [checkoutprice, setcheckoutprice] = useState(0);
  const [checkoutUser, setcheckoutUser] = useState(null);
  const [checkoutApponitMentId, setcheckoutApponitMentId] = useState(null);
  const [selectedServices, setSelectedServices] = useState([]);
  const [checkoutDetails, setcheckoutDetails] = useState([]);
  const [appointmentItem, setAppointmentItem] = useState(null);
  const [msg, setMessageAppointment] = useState(null);
  const [other, setOther] = useState(false);
  const [showLoading, setShowLoading] = useState(true);

  const [isModalOpen5, setIsModalOpen5] = useState(false);
  const handleCloseModal5 = () => {
    setIsModalOpen5(false);
  };
  const [isModalOpenRescheduled, setIsModalOpenRescheduled] = useState(false);
  const handleCloseModalRescheduled = () => {
    setIsModalOpenRescheduled(false);
  };


  const [isAppointmentDetailsModel, setAppointmentDetailsModel] =
    useState(false);
  const handleCloseAppointmentDetails = () => {
    setAppointmentDetailsModel(false);
  };

  const [isModalOpenCheckout, setIsModalOpenCheckout] = useState(false);
  const handleCloseModalCheckout = () => {
    setIsModalOpenCheckout(false);
  };
  const handleOpenModalCheckout = () => {
    setIsModalOpenCheckout(true);
  };

  const [isModalSelectPayment, setIsModalSelectPayment] = useState(false);
  const handleCloseModalSelectPayment = () => {
    setIsModalSelectPayment(false);
  };

  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [isSelected, setIsSelected] = useState("App");

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  const handleTimeChange = (e) => {
    setSelectedTime(e.target.value);
  };

  const RescheduleAppointmentFun = async (id) => {
    try {
      const apiData = {
        id: id,
        date: selectedDate,
        time: selectedTime,
      };
      const res = await PutAdminAPI(
        apiData,
        "business/appointments/appointmentReschedule"
      );
      if (res.statusCode === 200) {
        toast.success(res.message);
        handleCloseModalRescheduled();
        getAppointment();
      } else {
        toast.error(res.message);

      }
    } catch (error) {


    }
  };

  function formatDateToDayMonthDate(dateString) {
    // Create a Date object from the input dateString
    const date = new Date(dateString);

    // Define arrays for day and month names
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    // Get the day of the week and month from the Date object
    const dayOfWeek = daysOfWeek[date.getDay()];
    const month = months[date.getMonth()];

    // Get the day of the month
    const dayOfMonth = date.getDate();

    // Construct the formatted string
    const formattedDate = `${dayOfWeek}, ${month} ${dayOfMonth}`;

    return formattedDate;
  }

  const getSeviceList = async () => {
    const serviceList = await GetAdminAPI("business/service/getService");
    setServiceList(serviceList.data.services);
  };

  // loading
  useState(() => {
    setTimeout(() => {
      setShowLoading(false);
    }, 2000);
  }, []);

  useEffect(() => {
    if (userToken) {
      getAppointment();
      getSeviceList();
    }
  }, [status]);

  const statusMap = {
    2: "Accepted",
    3: "Rejected",
    4: "Completed",
    5: "Cancelled",
    6:'Expired',
  };
  const statusColor = {
    1: "orange", // Pending - red color
    2: "green", // Accepted - green color
    3: "red", // Rejected - orange color
    4: "green", // Completed - blue color
    5: "#ff6262", // Cancel - Red color
    6: "red", // Cancel - Red color

  };

  const getAppointment = async () => {
    try {
      toggleLoading(true);
      toggleLoading(true);
    try {
      const res = await GetAdminAPI(
          `business/appointments/myAppointments?offset=1&limit=100`
        );
        if (res.statusCode === 200) {
    
        setAppointments(res?.data?.myAppointments);
      toggleLoading(false);

        toggleLoading(false);
        
      }
    } catch (error) {
      toast.error("Something went worng!");
      toggleLoading(false);

      
    }
   
    } catch (error) {
      
    }finally{
      toggleLoading(false);
      
    }
    
  };


  const showData = appointments.find(
    (item) => item.isStatus === 4 || item.isStatus === 5
  );

  const showNewData = appointments.find(
    (item) => item.isStatus != 5 && item.isStatus != 4
  );

  const handleAcceptAppointment = async (id, status) => {
    try {
      const apiData = {
        id,
        isStatus: status,
      };
      toggleLoading(true);
      const res = await PutAdminAPI(apiData, "business/appointments/isStatus");
      if (res.statusCode === 200) {
        toast.success(res.message);
        const filterData = appointments?.map((app) => {
          if (app._id === id) {
            return {
              ...app,
              isStatus: 2,
            };
          }
          return app;
        });
        setAppointments(filterData);
      }
    } catch (error) {
      toast.error(error);
    } finally {
      toggleLoading(false);
        window.location.reload();
    }
  };

  

  const appointmentNoShow = async (id, chargeType) => {
    const apiData = {
      id,
      msg: "No show",
      noShow: true,
      chargeType: chargeType,
    };

    try {
      toggleLoading(true);
      const res = await PutAdminAPI(
        apiData,
        "business/appointments/appointmentNoShow"
      );
      if (res.statusCode === 200) {
        toast.success(res.message);
        handleCloseModal5();
        getAppointment();
      

      }
    } catch (error) {
      console.log(error);
      toggleLoading(false);
    }
  };


  const appointmentCancel = async (id) => {
    if (msg != null) {
      const apiData = {
        id,
        msg,
      };
      try {
        toggleLoading(true);
        const res = await PutAdminAPI(
          apiData,
          "business/appointments/appointmentCancel"
        );
        if (res.statusCode === 200) {
          toast.success(res.message);
          getAppointment();
          toggleLoading(false);

          handleCloseCancel();
        }
      } catch (error) {
        console.log(error);
        toggleLoading(false);

      }
    } else {
      toast.error("Please Select Reason");
    }
  };

  async function handleAddMoreServiceappointment() {
    try {
      const userId = checkoutUser.toString(); // Convert to a string
      const totalAmount = checkoutprice.toString(); // Convert to a string
      let data = null;

      let filteredAppointment = appointments.find(appointment => appointment._id === checkoutApponitMentId);
      let promoDiscount = filteredAppointment?.promoDiscount;

      if (promoDiscount !== null) {
        let discountAmount = (promoDiscount / 100) * totalAmount;
        let newPriceWithDiscount = totalAmount - discountAmount;
        data = {
          userId: userId,
          totalAmount: newPriceWithDiscount,
          appointmentId: checkoutApponitMentId,
          serviceName: selectedServices,
          previousAmount: totalAmount,
        };
      } else {
        data = {
          userId: userId,
          totalAmount: totalAmount,
          appointmentId: checkoutApponitMentId,
          serviceName: selectedServices,
        };
      }

      const resp = await PostAdminAPI(
        data,
        "business/appointments/addMoreServicesInAppointment"
      );

      if (resp.statusCode === 200) {
        toast.success("Add More Service In Appointment Successfully");
        getAppointment();
        handleCloseModalSelectPayment();
        handleCloseModalCheckout(false);
      }
    } catch (error) {
      console.log(error);
    }
  }

  // -----------Checkout --------------------

  async function handleCheckoutappointment() {
    try {
      const userId = checkoutUser.toString(); // Convert to a string
      const totalAmount = checkoutprice.toString(); // Convert to a string

      const data = {
        userId: userId,
        totalAmount: totalAmount,
        appointmentId: checkoutApponitMentId,
        serviceName: selectedServices,
      };

      const shouldCheckout = await Swal.fire({
        title: "Confirm Checkout",
        text: "Are you sure you want to checkout?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, proceed!",
      });

      if (shouldCheckout.isConfirmed) {
        const resp = await PostAdminAPI(
          data,
          "business/appointments/checkoutAppointment"
        );

        if (resp.statusCode === 200) {
          toast.success("Add More Service In Appointment Successfully");
          getAppointment();
          handleCloseModalSelectPayment();
          handleCloseModalCheckout(false);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function getAdditionalServiceName(additionalServiceName) {
    try {
      const resp = await GetAdminAPI("business/service/getService");
      const appointmentData = resp.data.services;

      const filteredAppointmentData = appointmentData.filter((appointment) =>
        additionalServiceName.includes(appointment.serviceName)
      );

      const setAdditionalService = filteredAppointmentData.map(
        (appointment) => appointment._id
      );

      const setAdditionalServicePrice = filteredAppointmentData.map(
        (appointment) => appointment.price
      );

      setcheckoutDetails(setAdditionalService);
      setSelectedServices(additionalServiceName);
      const newTotalPrice = setAdditionalServicePrice.reduce(
        (acc, price) => acc + price,
        0
      );

      setcheckoutprice(newTotalPrice);

    } catch (error) {
      console.log(error);
    }
  }

  const handleCheckboxChange = (serviceId) => {
    const updatedCheckoutDetails = [...checkoutDetails];
    const serviceIndex = updatedCheckoutDetails.indexOf(serviceId);

    if (serviceIndex === -1) {
      updatedCheckoutDetails.push(serviceId);
    } else {
      updatedCheckoutDetails.splice(serviceIndex, 1);
    }

    setcheckoutDetails(updatedCheckoutDetails);
    const selectedServicePrices = serviceList
      .filter((service) => updatedCheckoutDetails.includes(service._id))
      .map((service) => service.price);

    const selectedServiceName = serviceList
      .filter((service) => updatedCheckoutDetails.includes(service._id))
      .map((service) => service.serviceName);

    setSelectedServices(selectedServiceName);

    const newTotalPrice = selectedServicePrices.reduce(
      (acc, price) => acc + price,
      0
    );



    setcheckoutprice(newTotalPrice);
  };

  const funAdditionalService = async (serviceNameAdditional) => {
    try {
      const resp = await GetAdminAPI("business/service/getService");

      // Assuming resp.data.appointment is an array of objects
      const appointmentData = resp.data.services;
      // // Filter appointmentData based on the services present in serviceNameAdditional

      const filteredAppointmentData = appointmentData.filter((appointment) =>
        serviceNameAdditional.includes(appointment.serviceName)
      );

      const setAdditionalService = filteredAppointmentData.map(
        (appointment) => appointment._id
      );

      setcheckoutDetails(setAdditionalService);
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

 
    return (
      <div>
        <div>
          <div className="container-fluid main_splash2 p-0">
            {/* navbar start */}
            <MyAppointmentNavbar userDetails={userDetails} />

            <h5 className="my_account_h5">My Appointments</h5>
          </div>
          {/* navbar end */}
          {/* first section start */}
          <div className="container">
            {/* navs tabs start */}
            <ul
              className="nav nav-pills mb-3 mt-5"
              id="pills-tab"
              role="tablist"
            >
              <li className="nav-item mx-3 mt-4" role="presentation">
                <button
                  className="nav-link active a3"
                  id="pills-home-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-home"
                  type="button"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected="true"
                  onClick={() => setStatus(1)}
                >
                  My Appointments
                </button>
              </li>
              <li className="nav-item mx-3 mt-4" role="presentation">
                <button
                  className="nav-link a3"
                  id="pills-profile-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-profile"
                  type="button"
                  role="tab"
                  aria-controls="pills-profile"
                  aria-selected="false"
                  onClick={() => setStatus(2)}
                >
                  Complete Appointments
                </button>
              </li>
            </ul>
            <div className="span_div">
              <Link to="/appointment_calander">
                <i className="fa-solid fa-calendar-days"></i>
              </Link>
            </div>
            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                {/* card start */}
                <div className="container-fluid mt-5 mb-5">
                  <div className="row">
                    {appointments?.filter(
                      (item) => item.isStatus != 5 && item.isStatus != 4 && item.isStatus != 6 
                    ).length > 0 ? (
                      appointments
                        ?.filter(
                          (item) => item.isStatus != 5 && item.isStatus != 4 && item.isStatus != 6 
                        )
                        .map((appointment) => (
                          <div className="col-md-6" key={appointment._id}>
                            <div className="hair_and_makeup_card myAppointment pb-3">
                              <div className="row">
                                <div className="col-md-3 p-4">
                                  <img
                                    src={
                                      appointment.user.profileImage || tingrii
                                    }
                                    alt=""
                                    style={{
                                      borderRadius: "100px",
                                      width: "100px",
                                      height: "100px",
                                      display: "block",
                                      margin: "auto",
                                      objectFit: "cover",
                                    }}
                                    onClick={() => {
                                      setAppointmentDetailsModel(true);
                                      setAppointmentItem(appointment);
                                      setcheckoutDetails([
                                        appointment?.serviceId,
                                      ]);
                                      // }

                                      setcheckoutUser(appointment?.userId);
                                      setcheckoutApponitMentId(
                                        appointment?._id
                                      );

                                      if (appointment?.additionalService == 2) {
                                        setcheckoutprice(
                                          appointment?.additionalAmount
                                        );

                                        funAdditionalService(
                                          appointment?.additionalServiceName
                                        );
                                      } else if (appointment?.price) {
                                        setcheckoutprice(appointment?.price);
                                      } else {
                                        setcheckoutprice(appointment?.discountedPrice);

                                      }
                                    }}
                                  />
                                </div>

                                <div
                                  className="col-md-9 pt-4"
                                  style={{ position: "relative" }}
                                >

                                  <>
                                    {appointment.isStatus == 2 && (
                                      <>
                                        {!appointment?.noShow && (
                                          <>
                                            {appointment.expiredToken != 1 && (
                                              <>
                                                <button
                                                  className="cancel_appoinment cancel_appoinment_2"
                                                  onClick={() => {
                                                    setShowCancelModal(true);
                                                    setAppointmentItem(
                                                      appointment
                                                    );
                                                  }}
                                                >
                                                  Cancel
                                                </button>
                                                <button
                                                      className="cancel_appoinment cancel_appoinment_2"
                                                      style={{
                                                        top: "65px",
                                                        background: "transparent",
                                                        color: "black",
                                                        borderColor: "rgb(237, 172, 62)",
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() => {
                                                        if(!appointment?.isAppointmentDueDateExpired){
                                                          toast.warning(
                                                            "The appointment due date has not yet passed! You can only use this feature on or before the appointment expiration date.")
                                                          return;
                                                        }
                                                        setIsModalOpen5(true);
                                                        setAppointmentItem(appointment);
                                                      }}
                                                    >
                                                      No Show
                                                    </button>

                                                <button

                                                  className="cancel_appoinment cancel_appoinment_2"
                                                  style={{
                                                    top: "104px",
                                                    background: "transparent",
                                                    color: "black",
                                                    borderColor:
                                                      "rgb(237, 172, 62)",
                                                  }}
                                                  onClick={() => {
                                                    setIsModalOpenRescheduled(
                                                      true
                                                    );
                                                    setSelectedDate(
                                                      appointment.date
                                                    );
                                                    setSelectedTime(
                                                      appointment.time
                                                    );
                                                    setAppointmentItem(
                                                      appointment
                                                    );
                                                  }}
                                                >
                                                  Reshedule
                                                </button>
                                              </>
                                            )}

                                            {appointment.serviceType != 3 &&
                                              appointment.serviceType != 2 &&
                                              appointment.expiredToken != 1 && (
                                                <>
                                                  <button

                                                    className="cancel_appoinment cancel_appoinment_2"
                                                    style={{
                                                      top: "142px",
                                                      background: "transparent",
                                                      color: "black",
                                                      borderColor:
                                                        "rgb(237, 172, 62)",
                                                    }}
                                                    onClick={() => {
                                                      setIsModalSelectPayment(
                                                        true
                                                      );

                                                      if (appointment?.discountedPrice) {
                                                        setcheckoutprice(appointment?.discountedPrice);
                                                      }

                                                      if (
                                                        appointment.additionalService ===
                                                        2
                                                      ) {
                                                        getAdditionalServiceName(
                                                          appointment.additionalServiceName
                                                        );
                                                      } else {
                                                        setcheckoutDetails([
                                                          appointment?.serviceId,
                                                        ]);
                                                      }
                                                      setcheckoutUser(
                                                        appointment?.userId
                                                      );
                                                      setcheckoutApponitMentId(
                                                        appointment?._id
                                                      );

                                                      setcheckoutUser(
                                                        appointment?.userId
                                                      );
                                                      setcheckoutApponitMentId(
                                                        appointment?._id
                                                      );
                                                      setAppointmentItem(
                                                        appointment
                                                      );
                                                    }}
                                                  >
                                                    Checkout
                                                  </button>
                                                  <button

                                                    className="cancel_appoinment cancel_appoinment_2"
                                                    style={{
                                                      top: "179px",
                                                      background: "transparent",
                                                      color: "black",
                                                      borderColor:
                                                        "rgb(237, 172, 62)",
                                                    }}
                                                    onClick={() => {
                                                      setIsModalOpenCheckout(
                                                        true
                                                      );
                                                      if (
                                                        appointment.additionalService ===
                                                        2
                                                      ) {
                                                        getAdditionalServiceName(
                                                          appointment.additionalServiceName
                                                        );
                                                      } else {
                                                        setcheckoutDetails([
                                                          appointment?.serviceId,
                                                        ]);
                                                      }
                                                      setcheckoutUser(
                                                        appointment?.userId
                                                      );
                                                      setcheckoutApponitMentId(
                                                        appointment?._id
                                                      );

                                                      if (
                                                        appointment?.additionalService ==
                                                        2
                                                      ) {
                                                        setcheckoutprice(
                                                          appointment?.additionalAmount
                                                        );

                                                        funAdditionalService(
                                                          appointment?.additionalServiceName
                                                        );
                                                      } else if (appointment?.price) {
                                                        setcheckoutprice(
                                                          appointment?.price
                                                        );
                                                      } else {
                                                        setcheckoutprice(appointment?.discountedPrice);

                                                      }
                                                    }}
                                                  >
                                                    Add More Service
                                                  </button>
                                                </>
                                              )}
                                          </>
                                        )}
                                      </>
                                    )}
                                  </>
                                  {/* )} */}

                                  <SelectPaymentMethod
                                    show={isModalSelectPayment}
                                    handleClose={handleCloseModalSelectPayment}
                                    appointmentItem={appointmentItem}
                                    openCheckloutModel={handleOpenModalCheckout}
                                    appointments={appointments}
                                    setAppointments={setAppointments}
                                    handleCheckoutappointment={
                                      handleCheckoutappointment
                                    }
                                    setIsSelected={setIsSelected}
                                    isSelected={isSelected}
                                  />

                                  <AppointmentDetailsModel
                                    show={isAppointmentDetailsModel}
                                    handleClose={handleCloseAppointmentDetails}
                                    appointmentItem={appointmentItem}
                                    setOpenAppointmentUpdate={
                                      setOpenAppointmentUpdate
                                    }
                                  />

                                  <EditAppointmentModel
                                    show={openAppointmentUpdate}
                                    handleClose={handelClickAppointMentUpdate}
                                    appointmentItem={appointmentItem}
                                    serviceList={serviceList}
                                    selectedServices={selectedServices}
                                    checkoutDetails={checkoutDetails}
                                    checkoutprice={checkoutprice}
                                    checkoutUser={checkoutUser}
                                    checkoutApponitMentId={
                                      checkoutApponitMentId
                                    }
                                    setcheckoutApponitMentId={
                                      setcheckoutApponitMentId
                                    }
                                    setcheckoutUser={setcheckoutUser}
                                    setcheckoutprice={setcheckoutprice}
                                    setcheckoutDetails={setcheckoutDetails}
                                    setSelectedServices={setSelectedServices}
                                    handleCheckboxChange={handleCheckboxChange}
                                  />

                                  <NoShowModel
                                    show={isModalOpen5}
                                    handleClose={handleCloseModal5}
                                    appointmentNoShow={appointmentNoShow}
                                    appointmentItem={appointmentItem}
                                  />

                                  <Rescheduled
                                    show={isModalOpenRescheduled}
                                    handleClose={handleCloseModalRescheduled}
                                    appointmentNoShow={appointmentNoShow}
                                    appointmentItem={appointmentItem}
                                    setSelectedDate={setSelectedDate}
                                    selectedDate={selectedDate}
                                    selectedTime={selectedTime}
                                    setSelectedTime={setSelectedTime}
                                    handleDateChange={handleDateChange}
                                    handleTimeChange={handleTimeChange}
                                    RescheduleAppointmentFun={
                                      RescheduleAppointmentFun
                                    }
                                  />

                                  <CheckoutModel
                                    show={isModalOpenCheckout}
                                    handleClose={handleCloseModalCheckout}
                                    serviceList={serviceList}
                                    handleAddMoreServiceappointment={
                                      handleAddMoreServiceappointment
                                    }
                                    selectedServices={selectedServices}
                                    checkoutDetails={checkoutDetails}
                                    checkoutprice={checkoutprice}
                                    checkoutUser={checkoutUser}
                                    checkoutApponitMentId={
                                      checkoutApponitMentId
                                    }
                                    setcheckoutApponitMentId={
                                      setcheckoutApponitMentId
                                    }
                                    setcheckoutUser={setcheckoutUser}
                                    setcheckoutprice={setcheckoutprice}
                                    setcheckoutDetails={setcheckoutDetails}
                                    setSelectedServices={setSelectedServices}
                                    handleCheckboxChange={handleCheckboxChange}
                                    appointmentItem={appointmentItem}
                                  />

                                  <CancelModelBusiness
                                    show={showCencelModal}
                                    handleClose={handleCloseCancel}
                                    appointmentItem={appointmentItem}
                                    setAppointmentItem={setAppointmentItem}
                                    appointmentCancel={appointmentCancel}
                                    setMessageAppointment={
                                      setMessageAppointment
                                    }
                                    other={other}
                                    setOther={setOther}
                                  />

                                  <div className="" style={{ width: "67%" }}>
                                    <h5 className="hair_and_makeup_card_h5" style={{ textTransform: "capitalize" }}>
                                      {`${appointment?.user?.name} ${appointment.user.lastname === null
                                          ? ""
                                          : appointment.user.lastname
                                        }`}
                                    </h5>
                                    <h5 className="hair_and_makeup_card_h55">
                                      {appointment.service.serviceName} {""}
                                      {(appointment.additionalService === 2) && (
                                        <span
                                          className="view_more_service"
                                          onClick={() => {
                                            setshowMoreServiceModal(true);
                                            setAppointmentItem(appointment);
                                          }}
                                        >
                                          Additional Service
                                        </span>
                                      )}
                                    </h5>
                                    <p className="star_pettren">
                                      <Rating
                                        name="read-only"
                                        size="small"
                                        value={appointment.BusinessRating}
                                        precision={0.5}
                                        emptyIcon={
                                          <StarIcon
                                            style={{ opacity: 0.55 }}
                                            fontSize="inherit"
                                          />
                                        }
                                        readOnly
                                      />{" "}
                                      &nbsp;
                                      <span className="star_pettren_span">
                                        <b>
                                          {appointment?.BusinessRating.toString().substring(
                                            0,
                                            3
                                          )}
                                        </b>
                                        ({appointment?.BusinessReviewCount})
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="row px-3 mt-5 ">
                                <div className="col-md-12">
                                  <div className="woman_cut_left mt-4">
                                    <div className="row p-2 px-3">
                                      <div className=" pt-2">
                                        <p
                                          className="woman_cut_pp"
                                          style={{ color: "#363636" }}
                                        >
                                          <b>{appointment.date}</b>
                                        </p>
                                        <div
                                          className={`${appointment.isStatus !== 1
                                              ? "d-flex"
                                              : ""
                                            } justify-content-between align-items-center font-600`}
                                        >
                                          <p className="woman_cut_pp">
                                            {appointment.time}-
                                            {appointment.endTime}
                                          </p>
                                          {appointment.expiredToken === 1 ? (
                                            <>
                                              <span
                                                className="span_top"
                                                style={{
                                                  color: "blue",
                                                  fontWeight: "bold",
                                                }}
                                              >
                                                Payment Pending <br />
                                              </span>
                                            </>
                                          ) : (
                                            <>
                                              {appointment.isStatus !== 1 ? (
                                                <span
                                                  className="span_top"
                                                  style={{
                                                    color:
                                                      statusColor[
                                                      appointment.isStatus
                                                      ],
                                                    fontWeight: "bold",
                                                  }}
                                                >
                                                  {
                                                    statusMap[
                                                    appointment.isStatus
                                                    ]
                                                  }
                                                  <br />
                                                </span>
                                              ) : (
                                                <div className="d-flex gap-2">
                                                  <button
                                                    className="btn button_a1"
                                                    onClick={() =>
                                                      handleAcceptAppointment(
                                                        appointment._id,
                                                        2
                                                      )
                                                    }
                                                  >
                                                    Accept
                                                  </button>
                                                  <button
                                                    className="btn button_a2"
                                                    onClick={() =>
                                                      handleAcceptAppointment(
                                                        appointment._id,
                                                        3
                                                      )
                                                    }
                                                  >
                                                    Reject
                                                  </button>
                                                </div>
                                              )}
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <h5
                                  className="mt-4"
                                  style={{ color: "#6e6e6e" }}
                                >
                                  {appointment.isStatus !== "pending" && (
                                    <div
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      <i className="fa-solid fa-money-bills" />
                                      Payment : {appointment.paymentMethod}
                                      <span
                                        style={{
                                          color: "#EDAC3E",
                                          float: "right",
                                        }}
                                      >
                                        {appointment.serviceType !== 2 ? (
                                          <>
                                            {
                                              appointment.additionalService ===
                                                2 ? (
                                                <b>
                                                  ${appointment.additionalAmount}
                                                </b>
                                              ) : (
                                                <b>
                                                  $
                                                  {appointment.discountedPrice
                                                    ? appointment.discountedPrice
                                                    : appointment.service.price ||
                                                    appointment.service
                                                      .discountPrice}
                                                </b>
                                              )}
                                          </>
                                        ) : (
                                          <>
                                            <p> Personal Appointment </p>
                                          </>
                                        )}
                                      </span>
                                    </div>
                                  )}
                                </h5>
                                {appointment.serviceType !== 2 || appointment.serviceType !== 3 &&
                                  appointment.promoCode != "" && (
                                    <span
                                      style={{
                                        color: "#6e6e6e",
                                        float: "right",
                                      }}
                                    >
                                      <b>
                                        Promo Code :
                                        <span className="promo_corde_sett float-end">
                                          {appointment.promoCode}
                                        </span>

                                      </b>
                                      <br />
                                      <b>
                                        Discount{" "}
                                        <span className="promo_corde_sett float-end">
                                          {appointment.promoDiscount}%
                                        </span>
                                      </b>
                                    </span>
                                  )}
                              </div>
                            </div>
                          </div>
                        ))
                    ) : (
                      <h5 className="pt-4 pb-3 free_contant">
                        There is no Appointments!
                      </h5>
                    )}
                  </div>
                </div>
                {/* card end */}
              </div>
              <div
                className="tab-pane fade"
                id="pills-profile"
                role="tabpanel"
                aria-labelledby="pills-profile-tab"
              >
                {/* card start */}
                <div className="container-fluid mt-5 mb-5">
                  <div className="row">
                    {appointments &&
                      appointments
                        ?.filter(
                          (item) => item.isStatus === 4 || item.isStatus === 5 || item.isStatus === 6
                        )
                        .map((appointment) => (
                          <div className="col-md-6" key={appointment._id}>
                            <div className="hair_and_makeup_card myAppointment pb-3">
                              <div className="row">
                                <div className="col-3 p-4">
                                  <img
                                    src={
                                      appointment.user.profileImage
                                        ? appointment.user.profileImage
                                        : tingrii
                                    }
                                    alt=""
                                    style={{
                                      borderRadius: "100px",
                                      width: "100px",
                                      height: "100px",
                                      objectFit: "cover",
                                    }}
                                  />
                                </div>
                                <div className="col-9 pt-4">
                                  <h5 className="hair_and_makeup_card_h5" style={{ textTransform: "capitalize" }}>
                                    {appointment.user.name}{" "}
                                    {appointment.user.lastname}

                                    <span style={{ float: "right", marginRight: "20px" }}>
                                      {appointment.chargeType === 1 && (
                                        <span style={{ color: "red", fontSize: "17px" }}> Charge </span>
                                      )}
                                      {appointment.chargeType === 2 && (
                                        <span style={{ color: "red", fontSize: "17px" }}> Don't Charge </span>
                                      )}
                                    </span>
                                  </h5>
                                  <h5 className="hair_and_makeup_card_h55">
                                    <p>
                                      {appointment.service.serviceName}{" "}
                                      {appointment.additionalService === 2 && (
                                        <span
                                          className="view_more_service"
                                          onClick={() => {
                                            setshowMoreServiceModal(true);
                                            setAppointmentItem(appointment);
                                          }}
                                        >
                                          Additional Service
                                        </span>
                                      )}
                                    </p>
                                  </h5>



                                  <ViewMoreService
                                    show={showMoreServiceModal}
                                    handleClose={handleCloseMoreService}
                                    appointment={appointmentItem}
                                  />
                                  <p className="star_pettren">
                                    <Rating
                                      name="read-only"
                                      size="small"
                                      value={appointment.BusinessRating}
                                      precision={0.5}
                                      emptyIcon={
                                        <StarIcon
                                          style={{ opacity: 0.55 }}
                                          fontSize="inherit"
                                        />
                                      }
                                      readOnly
                                    />{" "}
                                    &nbsp;
                                    <span className="star_pettren_span">
                                      <b>
                                        {appointment?.BusinessRating.toString().substring(
                                          0,
                                          3
                                        )}
                                      </b>
                                      ({appointment?.BusinessReviewCount})
                                    </span>
                                  </p>
                                </div>
                              </div>
                              <div className="row px-3">
                                <div className="col-md-12">
                                  <div className="woman_cut_left">
                                    <div className="row p-2 px-3">
                                      <div className=" pt-2">
                                        <p
                                          className="woman_cut_pp"
                                          style={{ color: "#363636" }}
                                        >
                                          <b>{appointment.date}</b>
                                        </p>
                                        <div className="d-flex justify-content-between align-items-center font-600">
                                          <p className="woman_cut_pp">
                                            {appointment.time}-
                                            {appointment.endTime}
                                          </p>
                                          <span
                                            style={{
                                              color:
                                                statusColor[
                                                appointment.isStatus
                                                ],
                                              fontWeight: "bold",
                                            }}
                                          >
                                            {statusMap[appointment.isStatus]}
                                          </span>
                                        </div>

                                        {/* <p style={{ color: "#b35c5c",  fontWeight: "bold", textTransform: "capitalize" }}>{appointment.isStatus == 5 ?  appointment?.reasonMsg : " "}</p> */}

                                        {appointment.isStatus == 5 && (
                                          <p
                                            style={{
                                              color: "rgb(110, 110, 110)",
                                              fontWeight: "bold",
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            <hr />
                                            <span className="cancel_msg">
                                              cancel message: {" "}
                                            </span>
                                            {appointment.isStatus == 5
                                              ? appointment?.reasonMsg
                                              : " "}
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <h5
                                  className="mt-4"
                                  style={{ color: "#6e6e6e" }}
                                >
                                  {appointment.noShow == false ? (
                                    <>
                                      {" "}
                                      <i className="fa-solid fa-money-bills" />{" "}
                                      Payment Method :
                                      {appointment.paymentMethod === "PayByCard" && (
                                        <span style={{ color: "green", fontWeight: "bold", fontSize: "17px" }}> Pay By Card </span>
                                      )}
                                      {appointment.paymentMethod === "Cash" && (
                                        <span style={{ color: "green", fontWeight: "bold", fontSize: "17px" }}> Cash Payment </span>
                                      )}
                                      {appointment.paymentMethod === "card" && (
                                        <span style={{ color: "green", fontWeight: "bold", fontSize: "17px" }}> By App </span>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      <i className="fa-solid fa-eye-slash"></i>{" "}
                                      No Show
                                    </>
                                  )}
                                  <span
                                    style={{
                                      color: "#EDAC3E",
                                      float: "right",
                                    }}
                                  >
                                    {appointment.additionalService === 2 ? (
                                      <div>
                                        <b>${appointment.additionalAmount}</b>
                                      </div>
                                    ) : (
                                      <b>
                                        $
                                        {appointment.discountedPrice
                                          ? appointment.discountedPrice
                                          : appointment.service.price ||
                                          appointment.service.discountPrice}
                                      </b>
                                    )}
                                  </span>
                                </h5>
                                {appointment.serviceType !== 2 || appointment.serviceType !== 3 &&
                                  appointment.promoCode != "" && (
                                    <span
                                      style={{
                                        color: "#6e6e6e",
                                        float: "right",
                                      }}
                                    >
                                      <b>
                                        Promo Code :
                                        <span className="promo_corde_sett float-end">
                                          {appointment.promoCode}
                                        </span>

                                      </b>
                                      <br />
                                      <b>
                                        Discount{" "}
                                        <span className="promo_corde_sett float-end">
                                          {appointment.promoDiscount}%
                                        </span>
                                      </b>
                                    </span>
                                  )}
                              </div>
                            </div>
                          </div>
                        ))}
                    {!showData && (
                      <h5 className="pt-4 pb-3 free_contant">
                        There is no Appointments!
                      </h5>
                    )}
                  </div>
                </div>
                {/* card end */}
              </div>
            </div>
            {/* navs tabs end */}
          </div>
          {/* first section end */}
          {/* footer start */}
          <Footer />
          {/* footer end */}
        </div>
      </div>
    );
  
};
export default AppointmentsForBusiness;