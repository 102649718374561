import React from "react";
import { useTheme } from "@emotion/react";
import ComparisonChart from "app/views/dashboard/shared/ComparisonChart";
import productadd from "../../assets/images/productsad.svg";
import Footer from "../Footer";
import NavbarHome from "../Components/Home/NavbarHome";
import { useEffect } from "react";
import { GetAdminAPI } from "app/middleware";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useLoading } from "app/hooks/useLoader";

function TotalEarning() {
  const [type, setType] = useState("week")
  const [chartData, setChartData] = useState(null)
  const [product, setProduct] = useState(0)
  const [service, setService] = useState(0)
  const { toggleLoading } = useLoading();

  useEffect(() => {
    (async () => {
      toggleLoading(true);
      try {
        const response = await GetAdminAPI(`business/businessAnalysis/${type}`)
        if (response.statusCode === 200) {
          setChartData(response?.data?.week)
          setProduct(response?.data?.ProducttotalAmount)
          setService(response?.data?.ServicetotalAmount)
        } else {
          toggleLoading(false);
        }


      } catch (error) {
        toggleLoading(false);

      } finally {
        toggleLoading(false);

      }

    })()
  }, [])
  const chartType = async (e) => {
    let response;
    try {
      toggleLoading(true);
      if (e === "week" || e === "month") {
       response = await GetAdminAPI(`business/businessAnalysis/${e}`);
        if (response.statusCode === 200) {
          setChartData(e === "week" ? response.data.week : response.data.month);
          toggleLoading(false);

        }
      } else {
        response = await GetAdminAPI(`business/businessAnalysis/${e}`);
        if (response.statusCode === 200) {
          setChartData(response.data);
          toggleLoading(false);

        }
      }
      setProduct(response.data.ProducttotalAmount);
      setService(response.data.ServicetotalAmount);
      setType(e);
    } catch (error) {
      toggleLoading(false);

    } finally {
      toggleLoading(false);

    }
  }


  const theme = useTheme();
  return (
    <div>
      <NavbarHome />
      <div className="container pt-4">
        <Link className="withdrawal_screen mt-4" to={'/withdrawal'}>Withdrawal </Link>
      </div>
      <div className="container earning_select mt-5 pt-4 px-4">
        <div className="row">
          <div className="col-md-6">
            <h5 className="earning_select_h5">Total Earning</h5>
          </div>
          <div className="col-md-6">
            <select className="form-select earning_select_bar" aria-label="Default select example" onChange={(e) => chartType(e.target.value)}>
              <option value="week">Week</option>
              <option value="month">Month</option>
              <option value="year">Years</option>
            </select>
          </div>
        </div>
        {type === "week" && (
          <ComparisonChart
            height="350px"
            color={[theme.palette.primary.dark, theme.palette.primary.light]}
            chartData={chartData}
            type={type}
          />)}

        {type === "month" && (
          <ComparisonChart
            height="350px"
            color={[theme.palette.primary.dark, theme.palette.primary.light]}
            chartData={chartData}
            type={type}
          />)}

        {type === "year" && (
          <ComparisonChart
            height="350px"
            color={[theme.palette.primary.dark, theme.palette.primary.light]}
            chartData={chartData}
            type={type}
          />)}

      </div>

      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col-md-6 p-0">
            <div className="total_produt_earning p-4 me-5">
              <h1>
                <img src={productadd} alt="" /> &nbsp; &nbsp; Total Product
                Earning
              </h1>
              <h5>${product || 0}</h5>
            </div>
          </div>
          <div className="col-md-6 p-0">
            <div className="total_produt_earning p-4 ms-5">
              <h1>
                <img src={productadd} alt="" /> &nbsp; &nbsp; Total Service
                Earning
              </h1>
              <h5>${service || 0}</h5>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default TotalEarning;
