import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isModalOpen: false,
  products:[],
  product:null
}

export const productSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    handleProductModal: (state, action) => {
      state.isModalOpen = action.payload
    },
    getAllProdcuts: (state, action) => {
      state.products = action.payload;
    },
    getProduct: (state, action) => {
      state.product = action.payload;
    },
    deleteProduct: (state, action) =>{
      const allProducts = [...state.products];
      const filtererProducts =  allProducts.filter(item => item._id !== action.payload)
      state.products = filtererProducts;
    }
  },
})

// Action creators are generated for each case reducer function
export const { handleProductModal, getAllProdcuts, deleteProduct, getProduct } = productSlice.actions

export default productSlice.reducer