import React from 'react'
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import product from "../../../assets/images/productNotFound.jpg";

function CancelModelBusiness({ show, handleClose, setMessageAppointment, appointmentCancel, setOther, other, appointmentItem }, props) {
  return (
    <Modal
    show={show}
    {...props}
    aria-hidden="true"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    scrollable
    dialogClassName="custom__modal"
    backdropClassName="modal-backdrop fade"
    style={{ height: "90%" }}
>
    <Modal.Header className="modal-header modal-header1">
        <h1 className="modal-title fs-5" id="exampleModalToggleLabel2">
            Cancel Appointments 
        </h1>
        <button type="button" className="btn-close" onClick={handleClose} />
    </Modal.Header>
    <Modal.Body className="modal-body p-0" >
        <div className="modal-body text-start">
            <div className="hair_and_makeup_card pb-1 mb-3 ">
                <div className="row">
                    <div className="col-lg-3 ps-4 pt-3">
                        <img
                            src={
                                appointmentItem?.user?.profileImage ?  appointmentItem?.user?.profileImage : product
                            }
                            alt=""
                            width="100px"
                            height="100px"
                            style={{ borderRadius: 0 }}
                            className="mx-auto d-block"
                        />
                    </div>
                    <div className="col-lg-9 p-3 ">
                            <h5
                                className="hair_and_makeup_card_h5 pt-2"
                                style={{ fontSize: "19px" }}
                            > 
                                {appointmentItem?.user?.name} {" "} {appointmentItem?.user?.lastname}
                            </h5>
                        {/* <h5 className="hair_and_makeup_card_h55">Hair And Makeup Artist</h5> */}
                        <p
                            className="star_pettren"
                            style={{ fontSize: "12px", lineHeight: "12px" }}
                        >
                            <span className="star_pettren_span" style={{ fontSize: "15px", lineHeight: "12px" }}>
                                {appointmentItem?.service?.serviceName}
                            </span>
                        </p>
                        <h5
                            className="hair_and_makeup_card_h5 pt-2"
                            style={{ fontSize: "19px", lineHeight: "0px" }}
                        >
                            $
                          {appointmentItem?.discountedPrice
                            ? appointmentItem?.discountedPrice
                            : appointmentItem?.service?.price ||
                              appointmentItem?.service?.discountPrice}
                        </h5>
                    </div>
                </div>
            </div>
            <div className="row">
                <h5
                    className="hair_and_makeup_card_h5 pt-2"
                    style={{ fontSize: "19px" }}
                >
                    Reason For Cancellation
                </h5>

                <p
                    className="star_pettren"
                    style={{ fontSize: "12px", lineHeight: "12px" }}
                >
                    <span className="star_pettren_span" style={{ fontSize: "15px", lineHeight: "12px" }}>
                        <input type="radio" id="message" name="fav_language" 
                                                        onChange={(e) => {
                                                            setMessageAppointment(e.target.value) 
                                                            setOther(false)
                                                         }} 
                        value="Reschedule" />
                        <label htmlFor="message">&nbsp; Reschedule</label>
                    </span>
                </p>

                <p
                    className="star_pettren"
                    style={{ fontSize: "12px", lineHeight: "12px" }}
                >
                    <span className="star_pettren_span" style={{ fontSize: "15px", lineHeight: "12px" }}>
                        <input type="radio" id="message2" name="fav_language" 
                                                       onChange={(e) => {
                                                        setMessageAppointment(e.target.value) 
                                                        setOther(false)
                                                     }} 
                        value="Selected wrong service" />
                        <label htmlFor="message2">&nbsp; Selected wrong service</label>
                    </span>
                </p>

                <p
                    className="star_pettren"
                    style={{ fontSize: "12px", lineHeight: "12px" }}
                >
                    <span className="star_pettren_span" style={{ fontSize: "15px", lineHeight: "12px" }}>
                        <input type="radio" id="html4" name="fav_language" 
                                                       onChange={(e) => {
                                                        setMessageAppointment(e.target.value) 
                                                        setOther(false)
                                                     }}  
                        value="Wrong person" />
                        <label htmlFor="html4">&nbsp; Wrong person</label>
                    </span>
                </p>

                {/* <p
                    className="star_pettren"
                    style={{ fontSize: "12px", lineHeight: "12px" }}
                >
                    <span className="star_pettren_span" style={{ fontSize: "15px", lineHeight: "12px" }}>
                        <input type="radio" id="html5" name="fav_language"
                            onChange={(e) => {
                                setMessageAppointment(e.target.value)
                                setOther(false)
                            }}
                            value=" Shipping restrictions" />
                        <label htmlFor="html5">&nbsp; Shipping restrictions</label>
                    </span>
                </p> */}

                {/* <p
                    className="star_pettren"
                    style={{ fontSize: "12px", lineHeight: "12px" }}
                >
                    <span className="star_pettren_span" style={{ fontSize: "15px", lineHeight: "12px" }}>
                        <input type="radio" id="html7" name="fav_language"
                            onChange={(e) => {
                                setMessageAppointment(e.target.value)
                                setOther(false)
                            }}
                            value=" Order duplication " 
                        /> 
                        <label htmlFor="html7">&nbsp; Order duplication</label>
                    </span>
                </p> */}
                <p
                    className="star_pettren"
                    style={{ fontSize: "12px", lineHeight: "12px" }}
                >
                    <span className="star_pettren_span" style={{ fontSize: "15px", lineHeight: "12px" }}>
                        <input type="radio" id="other" name="fav_language" onClick={(e) =>{
                             setOther(true)
                             setMessageAppointment()
                        }} />
                        <label htmlFor="other">&nbsp; Other</label>
                    </span>
                </p>
                <p
                    className="star_pettren mt-2"
                    style={{ fontSize: "12px", lineHeight: "12px" }}
                >
                    {/* <span className="star_pettren_span mt-4" style={{ fontSize: "15px", lineHeight: "12px" }}>
                        Reason type..
                    </span> */}
                </p>

                <div className="row px-2 m-0" style={{ display: other ? 'block' : 'none' }}>
                    <textarea
                        rows="2"
                        cols="50"
                        className="name_input name_inputtext"
                        onChange={(e) => setMessageAppointment(e.target.value)}
                        placeholder="Reason type...."
                    />
                </div>
            </div>
        </div>
    </Modal.Body>
    <Modal.Footer className="modal-footer d-block">
        <center>
            <Link className="btn btn-primary cancel_btn1" onClick={() => appointmentCancel(appointmentItem._id)
            } >Submit</Link>
        </center>
    </Modal.Footer>
</Modal>
  )
}

export default CancelModelBusiness