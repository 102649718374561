import React from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

function ViewMoreService({ show, handleClose, appointment }, props) {
  console.log(show,"show____");
  return (
    <Modal
      show={show}
      {...props}
      aria-hidden="true"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
      dialogClassName="custom__modal"
      backdropClassName="modal-backdrop fade"
      style={{ height: "70%" }}
    >
      <Modal.Header className="modal-header modal-header1">
        <h1 className="modal-title fs-5" id="exampleModalToggleLabel2">
          Additional Services
        </h1>
        <button type="button" className="btn-close" onClick={handleClose} />
      </Modal.Header>
      <Modal.Body className="modal-body p-0 text-start ps-3 pt-3">
        <div>
          {appointment?.additionalService === 1  &&
            appointment?.additionalServiceName.map((service, index) => (
              <div key={index}>
                <p style={{ lineHeight: "10px", fontSize: "18px" }}>
                  {index + 1}. {service}
                </p>
                <hr />
              </div>
            ))}
             {appointment?.additionalService === 2  &&
            appointment?.additionalServiceName.map((service, index) => (
              <div key={index}>
                <p style={{ lineHeight: "10px", fontSize: "18px" }}>
                  {index + 1}. {service}
                </p>
                <hr />
              </div>
            ))}
        </div>
      </Modal.Body>
      <Modal.Footer className="modal-footer d-block">
        <center>
          <Link
            className="btn btn-primary cancel_btn1"
            onClick={() => handleClose(false)}
          >
            Close
          </Link>
        </center>
      </Modal.Footer>
    </Modal>
  );
}

export default ViewMoreService;
