import { CssBaseline } from "@mui/material";
import { useRoutes } from "react-router-dom";
import { MatxTheme } from "./components";
import { AuthProvider } from "./contexts/JWTAuthContext";
import { SettingsProvider } from "./contexts/SettingsContext";
import "react-toastify/dist/ReactToastify.css";
import routes from "./routes";
import "../fake-db";
import { ToastContainer } from "react-toastify";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { loginAction } from "./redux/reducer/auth";
import { receiveNotifications } from "./firebase/firesbaseHelper";
import moment from "moment";
import { useLoading } from "./hooks/useLoader";
import Loader from "./components/Loader/Loader";

const token = localStorage.getItem("token");
const user = JSON.parse(localStorage.getItem("user"));

const App = () => {
  const content = useRoutes(routes);
  const dispatch = useDispatch();
 const {isProcessing} = useLoading();


  useEffect(() => {
    if (token && user) {
      dispatch(loginAction(user));
    }
  }, [token]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker
      .register("/firebase-messaging-sw.js")
      .then(function (registration) {
        console.log("Registration successful, scope is:", registration.scope);
      })
      .catch(function (err) {
        console.log("Service worker registration failed, error:", err);
      });
  }
  // useEffect(() => {
    
  //   const user = JSON.parse(localStorage.getItem("user"));
  //  if(user){
  //   receiveNotifications(); // Start receiving messages after login
  //   const { trial } = user;
  //   // Get the expiration date from the trial
  //   let expDate = moment(trial.expDate);

  //   if (expDate) {
  //     console.log(expDate);
  //     let minutesLeft = expDate.diff(moment(), "minutes");

  //     if (minutesLeft < 0) {
  //       // Update the trial object if the time left is less than zero
  //       user.trial.isActive = false;
  //       // Save the updated user object back to local storage
  //       localStorage.setItem("user", JSON.stringify(user));
  // }
  //   // Calculate the time left until expiration in minutes

  //  }
   
  //   }



  //   // Cleanup function to remove the listener if your component unmounts
  //   return () => {
  //     // Perform any cleanup if necessary
  //   };
  // }, []);

  useEffect(() => {

  }, [isProcessing])
  

  return (
    <SettingsProvider>
      <AuthProvider>
        <MatxTheme>
          <CssBaseline />
          <ToastContainer />
          {isProcessing && <Loader/>}
         
          {content}
        </MatxTheme>
      </AuthProvider>
    </SettingsProvider>
  );
};

export default App;
