import React from 'react'
import logo from '../assets/images/logo.png'
export default function Loading() {
  return (
    <div>
        <div className="loader">
      <img src={logo} alt="Logo" className="loader-logo" />
      <div className="loader-spinner"></div>
    </div>
    </div>
  ) 
}