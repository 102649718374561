
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import firebase from './firebaseConfig';
// Initialize Firebase

const messaging = getMessaging(firebase);

const getFCMToken = async () => {
    try {
        // Request notification permission from the user
        const permission = await Notification.requestPermission();
        if (permission !== 'granted') {
            // throw new Error('Permission not granted for Notification');
        }

         // Get FCM token without explicitly passing a VAPID key
         const token = await getToken(messaging);
         console.log(token ,"token");
        if (token) {
            console.log('FCM Token:', token);
            return token;
        } else {
            // throw new Error('Failed to get FCM token');
        }
    } catch (error) {
        console.error('Error in getting FCM token:', error);
        return null;
    }
};

const receiveNotifications = () => {
    onMessage(messaging, (payload) => {
        console.log('Message received. ', payload);
        // Customize notification handling or display here
    });
};

export { getFCMToken, receiveNotifications };