import React from 'react'
import logo from '../../assets/images/logo.png'
import docusing  from '../../assets/images/valerie-elash-SneW1_eWjyY-unsplash.jpg'
import { Link } from 'react-router-dom'
import Footer from '../Footer'
import SEO from '../SEO'

function ForgetChangePassword() {
  return (
    <div className="container-fluid p-0">
    <SEO title="forget password account" content="forget password account" keywords="Shop, Email, Phone, Barber, Service" image={logo} />
    {/* navbar start */}
    <nav className="navbar navbar-expand-lg navbar-light pt-4">
      <div className="container">
        <Link className="navbar-brand colo" to="/"><img src={logo} alt="" width="120px" /></Link>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
          </ul>
          <form className="d-flex">
            <ul className="navbar_list">
              <li>Set Up My Business</li>
              <li>
                <Link to="/user/login" className="login_btn">Log In</Link>
              </li>
              <li>
                <Link to="/user/signup" className="sign_btn">Sign Up</Link>
              </li>
              <li>Help</li>
              <li className="icons_list"><i className="fa-solid fa-magnifying-glass" /> <i className="fa-regular fa-user" /> <i className="fa-solid fa-bag-shopping" /></li>
            </ul>
          </form>
        </div>
      </div>
    </nav>
    {/* navbar end */}
    {/* first section */}
    <div className="container">
      <div className="row mt-5">
        <div className="col-md-5 as_customer_left">
          <img src={docusing} alt="" width="100%" className="img-fluid" />
        </div>
        <div className="col-md-7 as_customer_right ps-5">
          <img src={logo} alt="" width="120px" className="mt-4" /><br />
          <h5 className="pt-3">New Password</h5>
          {/* <p className="already_account_p ">If you've forgotten your password, enter your email and we will <br /> send you a link to create a new one.</p> */}
          <p className="already_account_p "></p>
          <form action className="mt-3">
            <input type="password" className="name_input mt-4" placeholder="Enter new password" /><br />
            <input type="password" className="name_input mt-4" placeholder="Confirm password" />
          </form>
          <div className="mt-5">
            <a href="reset_password.php" className="create_account_btn mt-3">Done</a> <br />
          
          </div>
          <p className="already_account_p pt-4">If you require additional help, please contact us <br /> at help@crowdexotic.com </p>
        </div>
      </div>
    </div>
    <br />
    <Footer />
  </div>
  )
}

export default ForgetChangePassword