import React from "react";
import sendmassage from "../../assets/images/send_massage.png";
import shareicon from "../../assets/images/share_icon.png";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { GetAdminAPI, PostAdminAPI } from "app/middleware";
import { useState } from "react";
import AppLayout from "app/Layout/AppLayout";
import { toast } from "react-toastify";
import dummyImage from "../../assets/images/dummy-image.jpg";
import Loading from "app/components/Loading";

function LiveChat() {
  const { user } = useSelector((state) => state.auth);

  const [chatlist, setChatlist] = useState([]);
  const [chatSelect, setChatSelect] = useState(null);
  const [msg, setMsg] = useState("");
  const [msglist, setMsgList] = useState([]);
  const [showLoading, setShowLoading] = useState(true);

  // loading
  useState(() => {
    setTimeout(() => {
      setShowLoading(false);
    }, 2000);
  }, []);

  useEffect(() => {
    chatListFun();
  }, []);

  const chatListFun = async () => {
    const resp = await GetAdminAPI(
      user?.role === "business"
        ? "/business/message/messageList?offset=&limit="
        : "/message/messageList?offset=&limit="
    );
    setChatlist(resp.data.message);
  };

  const timeChange = (timestamp) => {
    const date = new Date(timestamp);

    const hours = date.getHours();
    const minutes = date.getMinutes();

    let convertedHours = hours % 12;
    if (convertedHours === 0) {
      convertedHours = 12;
    }

    const formattedTime = `${convertedHours
      .toString()
      .padStart(2, "0")}:${minutes.toString().padStart(2, "0")}${
      hours < 12 ? " AM" : " PM"
    }`;

    return formattedTime;
  };

  const selectChatUser = async (chat) => {
    const respJson = await GetAdminAPI(
      user?.role === "business"
        ? `business/message/messageDetails/${chat?._id}`
        : `message/messageDetails/${chat?._id}`
    );
    setChatSelect(respJson?.data?.messageDetail);
    setMsgList(respJson?.data?.messageDetail?.messageList);
  };

  const messageSend = async (id, reciverId) => {
    const apiData = {
      id: id,
      reciverId: reciverId,
      message: msg,
    };
    try {
      const res = await PostAdminAPI(
        apiData,
        user?.role === "business"
          ? "business/message/sendMessageChat"
          : "message/sendMessageChat"
      );
      if (res.statusCode === 200) {
        setMsg("");

        if (user?.role === "business") {
          const newMsg = {
            createdAt: new Date(),
            isMyMsg: false,
            message: msg,
            recipients: chatSelect?.recipients,
            sender: chatSelect?.senders,
          };

          msglist.push(newMsg);

          setMsgList(msglist);
        } else {
          const newMsg = {
            createdAt: new Date(),
            isMyMsg: true,
            message: msg,
            recipients: chatSelect?.recipients,
            sender: chatSelect?.senders,
          };

          msglist.push(newMsg);

          setMsgList(msglist);
        }

        toast.success(res.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const setSearchUser = async (value) => {
    if (value) {
      const data = chatlist.filter((item) =>
        item.recipients.name.toLowerCase().includes(value.toLowerCase())
      );
      setChatlist(data);
    } else {
      chatListFun();
    }
  };

  if (showLoading) {
    return <Loading />;
  } else {
    return (
      <div>
        <AppLayout>
          {user?.role === "business" ? (
            <div className="container live_chat mt-5 mb-5">
              <div className="row">
                <div className="col-lg-4 col-md-6 live_chat_left p-0">
                  <div className="chat_inbox">
                    <h5 className="user_name_gaurav">
                      <img
                        src={
                          user?.profileImage ||
                          "https://cdn-icons-png.flaticon.com/512/219/219983.png"
                        }
                        alt=""
                        className="user_img m-3"
                      />
                      {user?.name} {user?.lastname}
                    </h5>
                  </div>
                  <div className="search_bar mx-4 px-2">
                    <i className="fa-solid fa-magnifying-glass me-1"></i>{" "}
                    <input type="text" placeholder="Search a new chat" />
                  </div>
                  <hr />
                  <div className="chat_list_scroll_bar">
                    {chatlist.length > 0 ? (
                      chatlist?.map((item, index) => (
                        <div
                          className="card_massge"
                          key={index}
                          onClick={() => selectChatUser(item)}
                        >
                          <div className="row">
                            <div className="col-lg-2 col-sm-3">
                              <img
                                src={
                                  item?.senders?.profileImage ||
                                  "https://cdn-icons-png.flaticon.com/512/219/219983.png"
                                }
                                alt=""
                                className="user_img mx-3 mb-2"
                              />
                            </div>
                            <div className="col-lg-7 col-sm-6 user_name">
                              <h5>
                                {item?.senders.name} {item?.senders.lastname}
                              </h5>
                              <p>{item?.message}</p>
                            </div>
                            <div className="col-sm-3 massage_timme">
                              <p>{timeChange(item?.updatedAt)}</p>
                            </div>
                          </div>
                          <hr />
                        </div>
                      ))
                    ) : (
                      <p style={{ textAlign: "center" }}>There is no chat</p>
                    )}
                  </div>
                </div>

                {chatSelect ? (
                  <div className="col-lg-8 live_chat_left_right col-md-6 p-0">
                    <div className="chat_chat">
                      <h5 className="user_name_gaurav">
                        <img
                          src={
                            chatSelect?.senders?.profileImage ||
                            "https://cdn-icons-png.flaticon.com/512/219/219983.png"
                          }
                          alt=""
                          className="user_img m-3"
                        />
                        {chatSelect?.senders?.name}{" "}
                        {chatSelect?.senders?.lastname}
                        {/* <span className="float-end mt-4 pt-1 me-3">
                      {" "}
                      <div className="dropdown">
                        <button
                          className="btn dropdown-toggle"
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i className="fa-solid fa-ellipsis-vertical dot_icon"></i>
                        </button>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton1"
                        >
                          <li>
                            <a className="dropdown-item" href="#">
                              Lorem
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">
                              Lorem
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">
                              Lorem
                            </a>
                          </li>
                        </ul>
                      </div>
                    </span> */}
                      </h5>
                    </div>

                    <div className="chat_chat_start">
                      {msglist.length > 0 &&
                        msglist?.map((item, index) => (
                          <div style={{ overflow: "auto" }} key={index}>
                            {!item?.isMyMsg && (
                              <div className="side_msg d-flex">
                                <div className="side_msg_spann">
                                  {item?.message}{" "}
                                  <span>{timeChange(item?.createdAt)}</span>
                                </div>
                                <img
                                  src={
                                    item?.recipient?.profileImage ||
                                    "https://cdn-icons-png.flaticon.com/512/219/219983.png"
                                  }
                                  alt=""
                                  className="user_img m-3"
                                />
                              </div>
                            )}

                            {item?.isMyMsg && (
                              <div className="mt-5 d-flex">
                                <img
                                  src={
                                    item?.sender?.profileImage ||
                                    "https://cdn-icons-png.flaticon.com/512/219/219983.png"
                                  }
                                  alt=""
                                  className="user_img m-3"
                                />
                                <div className="side_msg_spann">
                                  {item?.message}{" "}
                                  <span>{timeChange(item?.createdAt)}</span>
                                </div>
                              </div>
                            )}
                          </div>
                        ))}
                    </div>

                    <div className="other_search_bar p-1 ps-5">
                      <div className="other_search_bar_in">
                        {/* <img src={shareicon} alt="" className="socail_icon" /> */}
                        <input
                          type="text"
                          placeholder="Search a new chat"
                          onChange={(e) => setMsg(e.target.value)}
                          value={msg}
                        />
                        <img
                          src={sendmassage}
                          alt=""
                          className="socail_icon"
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            messageSend(
                              chatSelect?._id,
                              chatSelect?.senders?._id
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="col-lg-8 live_chat_left_right col-md-6 p-0">
                    <p
                      style={{
                        textAlign: "center",
                        marginTop: "60px",
                        fontFamily: "monospace",
                      }}
                    >
                      There is no chat
                    </p>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="container live_chat mt-5 mb-5">
              <div className="row">
                <div className="col-lg-4 col-md-6 live_chat_left p-0">
                  <div className="chat_inbox">
                    <h5 className="user_name_gaurav">
                      <img
                        src={
                          user?.profileImage ||
                          "https://cdn-icons-png.flaticon.com/512/219/219983.png"
                        }
                        alt=""
                        className="user_img m-3"
                      />
                      {user?.name} {user?.lastname}
                    </h5>
                  </div>
                  <div className="search_bar mx-4 px-2">
                    <i className="fa-solid fa-magnifying-glass me-1"></i>{" "}
                    <input
                      type="text"
                      placeholder="Search a new chat"
                      onKeyUp={(e) => setSearchUser(e.target.value)}
                    />
                  </div>
                  <hr />
                  <div className="chat_list_scroll_bar">
                    {chatlist.length > 0 ? (
                      chatlist?.map((item, index) => (
                        <div
                          className="card_massge"
                          key={index}
                          onClick={() => selectChatUser(item)}
                        >
                          <div className="row pt-3 mb-3 border-bottom">
                            <div className="col-lg-2 col-sm-3">
                              <img
                                src={
                                  item?.recipients?.profileImage ||
                                  "https://cdn-icons-png.flaticon.com/512/219/219983.png"
                                }
                                alt=""
                                className="user_img mx-3 mb-2"
                              />
                            </div>
                            <div className="col-lg-7 col-sm-6 user_name">
                              <h5>
                                {item?.recipients.name}{" "}
                                {item?.recipients.lastname}
                              </h5>
                              <p>{item?.message}</p>
                            </div>
                            <div className="col-sm-3 massage_timme">
                              <p>{timeChange(item?.updatedAt)}</p>
                            </div>
                          </div>
                          {/* <hr /> */}
                        </div>
                      ))
                    ) : (
                      <p style={{ textAlign: "center" }}>There is no chat</p>
                    )}
                  </div>
                </div>

                {chatSelect ? (
                  <div className="col-lg-8 live_chat_left_right col-md-6 p-0">
                    <div className="chat_chat">
                      <h5 className="user_name_gaurav">
                        <img
                          src={
                            chatSelect?.recipients?.profileImage ||
                            "https://cdn-icons-png.flaticon.com/512/219/219983.png"
                          }
                          alt=""
                          className="user_img m-3"
                        />
                        {chatSelect?.recipients?.name}{" "}
                        {chatSelect?.recipients?.lastname}
                        {/* <span className="float-end mt-4 pt-1 me-3">
                      {" "}
                      <div className="dropdown">
                        <button
                          className="btn dropdown-toggle"
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i className="fa-solid fa-ellipsis-vertical dot_icon"></i>
                        </button>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton1"
                        >
                          <li>
                            <a className="dropdown-item" href="#">
                              Lorem
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">
                              Lorem
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">
                              Lorem
                            </a>
                          </li>
                        </ul>
                      </div>
                    </span> */}
                      </h5>
                    </div>

                    <div className="chat_chat_start">
                      {msglist &&
                        msglist?.map((item, index) => (
                          <div style={{ overflow: "auto" }} key={index}>
                            {item?.isMyMsg && (
                              <div className="side_msg d-flex">
                                <div className="side_msg_spann">
                                  {item?.message}{" "}
                                  <span>{timeChange(item?.createdAt)}</span>
                                </div>
                                <img
                                  src={
                                    item?.sender?.profileImage ||
                                    "https://cdn-icons-png.flaticon.com/512/219/219983.png"
                                  }
                                  alt=""
                                  className="user_img m-3"
                                />
                              </div>
                            )}

                            {!item?.isMyMsg && (
                              <div className=" mt-5 d-flex">
                                <img
                                  src={
                                    item?.recipient?.profileImage ||
                                    "https://cdn-icons-png.flaticon.com/512/219/219983.png"
                                  }
                                  alt=""
                                  className="user_img m-3"
                                />
                                <div className="side_msg_spann">
                                  {item?.message}{" "}
                                  <span>{timeChange(item?.createdAt)}</span>
                                </div>
                              </div>
                            )}
                          </div>
                        ))}
                    </div>

                    <div className="other_search_bar p-1 ps-5">
                      <div className="other_search_bar_in">
                        {/* <img src={shareicon} alt="" className="socail_icon" /> */}

                        <input
                          type="text"
                          placeholder="Search a new chat"
                          onChange={(e) => setMsg(e.target.value)}
                          value={msg}
                        />
                        <img
                          src={sendmassage}
                          alt=""
                          className="socail_icon"
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            messageSend(
                              chatSelect?._id,
                              chatSelect?.recipients?._id
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="col-lg-8 live_chat_left_right col-md-6 p-0">
                    <p
                      style={{
                        textAlign: "center",
                        marginTop: "60px",
                        fontFamily: "monospace",
                      }}
                    >
                      There is no chat
                    </p>
                  </div>
                )}
              </div>
            </div>
          )}
        </AppLayout>
      </div>
    );
  }
}

export default LiveChat;
