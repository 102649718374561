import axios from "axios";
import { liveUrl } from "../../app/utils/common";



export const PostAPI = async (data, url) => {
 
  var raw = JSON.stringify(data);

  const resp = await fetch(`${liveUrl}/${url}`, {
    method: 'POST',
    headers: {
      "Content-Type":"application/json"
    },
    body: raw
  })
  const respJson = await resp.json()


  return respJson;
}

export const GetAPI = async (url) => {
  const resp = await fetch(`${liveUrl}/${url}`, {
    method: 'GET'
  })
  const respJson = await resp.json()
  return respJson;
}

export const GetAPIWithBody = async (url, data) => {
  const resp = await axios.post(`${liveUrl}/${url}`, data)
  const respJson = await resp.json()
  return respJson;
}

export const GetAdminAPI = async (url) => {
  const token = localStorage.getItem('token')
  const resp = await fetch(`${liveUrl}/${url}`, {
    method: 'GET',
    headers: {
      "Authorization": `Bearer ${token}`
    }
  })
  const respJson = await resp.json()
  return respJson;
}

export const PostAdminAPI = async (data, url) => {
  var raw = JSON.stringify(data);

  const token = localStorage.getItem('token')
console.log(raw,"data, urldata, urldata, urldata, urldata, url")
  const resp = await fetch(`${liveUrl}/${url}`, {
    method: 'POST',
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type":"application/json"
    },
    body: raw
  })
  const respJson = await resp.json()
  console.log(respJson, respJson,"respJson")
  return respJson;
}

export const PutAdminAPI = async (data, url) => {
  var raw = JSON.stringify(data);

  const token = localStorage.getItem('token')

  const resp = await fetch(`${liveUrl}/${url}`, {
    method: 'PUT',
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type":"application/json"
    },
    body: raw
  })
  const respJson = await resp.json()
  return respJson;
}

export const DeleteAPI = async (url) => {
  const token = localStorage.getItem('token')

  const resp = await fetch(`${liveUrl}/${url}`, {
    method: 'DELETE',
    headers: {
      "Authorization": `Bearer ${token}`
    }
  })
  const respJson = await resp.json()
  return respJson;
}

export const PostAdminFileAPI = async (data, url) => {
  //var raw = JSON.stringify(data);

  const token = localStorage.getItem('token')

  const resp = await fetch(`${liveUrl}/${url}`, {
    method: 'POST',
    headers: {
      "Authorization": `Bearer ${token}`
    },
    body: data
  })
  const respJson = await resp.json()
  return respJson;
}

export const PutAdminFileAPI = async (data, url) => {

  const token = localStorage.getItem('token')

  const resp = await fetch(`${liveUrl}/${url}`, {
    method: 'PUT',
    headers: {
      "Authorization": `Bearer ${token}`
    },
    body: data
  })
  const respJson = await resp.json()
  return respJson;
}