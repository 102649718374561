// google login key
export const clientId = "851668441042-8lgan79dgj06c5s1rd6oq27j8vdkb4mf.apps.googleusercontent.com"
// export const clientId = "475277518550-up44p7du7c2gom18fq0lv6aicf82g13p.apps.googleusercontent.com"
export const clientSeceret = "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"
const successUrlAppointment = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_SUCCESS_URL_PROD : process.env.REACT_APP_SUCCESS_URL_DEV;
const cancelUrl = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_CANCEL_URL_PROD : process.env.REACT_APP_CANCEL_URL_DEV;
const successPaymentUrl = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_SUCCESS_URL_PROD_PAYMENT : process.env.REACT_APP_SUCCESS_URL_DEV_PAYMENT;
// const webUrl = process.env.NODE_ENV === 'production' ? 'https://www.shoponestop.net' : 'http://localhost:3001';
let webUrl = 'https://www.shoponestop.net';

let baseUrl, liveUrl, imageUrl;
// baseUrl = "https://www.shoponestop.net/sosapi/v1";
// liveUrl = "https://www.shoponestop.net/sosapi/v1";
// imageUrl = "https://www.shoponestop.net/sosapi";
if (process.env.NODE_ENV === "production") {
  baseUrl = "https://www.shoponestop.net/sosapi/v1";
  liveUrl = "https://www.shoponestop.net/sosapi/v1";
  imageUrl = "https://www.shoponestop.net/sosapi";

} else {
  baseUrl = "https://www.shoponestop.net/sosapi/v1";
  liveUrl = "https://www.shoponestop.net/sosapi/v1";
  imageUrl = "https://www.shoponestop.net/sosapi";

}
export { baseUrl, liveUrl, imageUrl ,successUrlAppointment ,cancelUrl,successPaymentUrl ,webUrl};