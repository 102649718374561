import NavbarHome from 'app/site/Components/Home/NavbarHome'
import Footer from 'app/site/Footer'
import React from 'react'

const AppLayout = ({children}) => {
  return (
   <>
    <NavbarHome />
    {children}
    <Footer />
   </>
   
  )
}

export default AppLayout