import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../../assets/images/logo.png";
import { Badge} from "@mui/material";

import { ShoppingBag } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { GetAdminAPI, PutAdminAPI } from "app/middleware";
import { useEffect } from "react";
import { getCart } from "app/redux/reducer/cartSlice";
import Swal from "sweetalert2";
import dummyImage from "../../../assets/images/dummy-image.jpg";
import { useState } from "react";
import { toast } from "react-toastify";

function NavbarHome() {
  const token = localStorage.getItem("token");
  const { user } = useSelector((state) => state.auth);
  const { cart } = useSelector((state) => state.cart);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [notification, setNotifications] = useState([]);
  const [countNotification, setCountNotification] = useState();

  const logoutFun = () => {
    Swal.fire({
      title: "Are you sure to logout?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Logout !",
    }).then(async (result) => {
      if (result.isConfirmed) {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        localStorage.clear();
        // window.location = "/user/login";
        navigate("/user/login");
      }
    });
  };

  const timeChangeCurrent = (time) => {
    const dateString = time;
    const date = new Date(dateString);
    const options = { weekday: "short", month: "long", day: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);
    return formattedDate;
  };

  useEffect(() => {
    if (token) {
      getCartCount();
    }
  }, [token]);

  const getCartCount = async () => {
    const res = await GetAdminAPI("/cart/getCartCount");
    dispatch(getCart(res?.data?.cartTotal || 0));
  };

  const getNotification = async () => {
    const notification = await GetAdminAPI(
      user.role == "user"
        ? "user/notification/myNotification?offset=&limit="
        : "business/notification/myNotification?offset=&limit="
    );

    setNotifications(notification.data);
    const newdata = notification?.data?.notfications?.filter(
      (item) => item.isRead == false
    );
    setCountNotification(newdata?.length || 0);
  };

  useEffect(() => {
    if (user) {
      getNotification();
    }
  }, []);

  const readNotifications = async (id, type) => {
    const data = {
      id: id,
      type: type,
      userId: user._id
    };
    try {
      if (type == "order") {
        navigate("/my_order");
      } else if (type == "service") {
        user.role == "user"
          ? navigate("/appointments")
          : navigate("/my_appointments");
      } else {
        navigate("/live_chat");
      }
      await PutAdminAPI(data, "user/notification/isRead");
      getNotification();
    } catch (error) {
      toast.error(error.message)

    }

  };

  const switchRoleFun = async (type) => {
    Swal.fire({
      title:
        type == "user"
          ? "Are you sure you want to become a User?"
          : "Are you sure you want to become a Service provider?",
      // text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes !",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const data = {
          userId: user._id,
          role: type,
        };
        const resp = await PutAdminAPI(data, "auth/updateRole");
        if (resp.statusCode === 200) {
          if (
            (resp?.data?.user?.role === "business" &&
              user?.businessname == null) ||
            user?.businessname == ""
          ) {
            window.location = "/business_name";
            localStorage.setItem("token", resp.data.token);
            localStorage.setItem("user", JSON.stringify(resp.data.user));
            return;
          }
          localStorage.setItem("token", resp.data.token);
          localStorage.setItem("user", JSON.stringify(resp.data.user));
          window.location = "/";
        }
      }
    });
  };
  const clearAllNotifications = async () => {
    try {
      let data = {
        userId: user._id
      }
      const resp = await PutAdminAPI(data, "user/notification/clearAll");

      if (resp.statusCode === 200) {
        getNotification();
        toast.success(resp.message);

      } else {
        toast.error(resp.message);

      }
    } catch (error) {

    }

  }
  return (
    <nav className="navbar navbar-expand-lg navbar-light pt-4">
      <div className="container">
        <Link className="navbar-brand colo" to="/">
          <img src={logo} alt="" width="120px" />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0"></ul>
          <form className="d-flex">
            {token && user?.role !== "admin" ? (
              <ul className="navbar_list">
                {pathname === "/" && (
                  <li>
                    &nbsp;{" "}
                    <span className="link_color_change pt-3">
                      <Link to="/help">Help</Link>
                    </span>
                  </li>
                )}
                <li>
                  <div className="dropdown">
                    <button
                      className="btn dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <Link style={{ color: "#edac3e" }} to="/add_to_cart">
                        <Badge badgeContent={countNotification} color="primary">
                          <i className="fa-solid fa-bell notification_icons mt-2"></i>
                        </Badge>
                      </Link>
                    </button>
                    <ul
                      className="dropdown-menu dropdown_menu_2 pb-0 overflow-hidden"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      {notification?.notfications?.length > 0 ? (
                        <>
                          <div className="container">
                            <div className="row justify-content-end">
                              <div className="col-auto">
                                <button
                                  onClick={(e) => {
                                    e.preventDefault(); // Prevent page refresh
                                    clearAllNotifications();
                                  }}
                                  className="btn align clear-notification-btn"
                                >
                                  Clear All <i class="fa-regular fa-circle-xmark"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                          {notification?.notfications?.map((item, index) => (
                            <div className="row border-bottom pt-2" key={index}>
                              <div className="col-2 review_notifaction_icon">
                                {item.product && (
                                  <img
                                    src={item.product.productImages[0] || dummyImage}
                                    alt=""
                                    style={{
                                      width: 32,
                                      height: 32,
                                      left: "20px",
                                      position: "relative",
                                      top: 3,
                                      borderRadius: 20,
                                      objectFit: "cover",
                                    }}
                                  />
                                )}

                                {item.service && (
                                  <img
                                    src={item?.business.profileImage || dummyImage}
                                    alt=""
                                    style={{
                                      width: 32,
                                      height: 32,
                                      left: "20px",
                                      position: "relative",
                                      top: 3,
                                      borderRadius: 20,
                                      objectFit: "cover",
                                    }}
                                  />
                                )}

                                {item?.status == "payment" && (
                                  <>
                                    {item.business && (
                                      <img
                                        src={item.business.profileImage || dummyImage}
                                        alt=""
                                        style={{
                                          width: 32,
                                          height: 32,
                                          left: "20px",
                                          position: "relative",
                                          top: 3,
                                          borderRadius: 20,
                                          objectFit: "cover",
                                        }}
                                      />
                                    )}
                                  </>
                                )}
                              </div>
                              <div className="col-10 review_notifaction">
                                {item.status == "product" ? (
                                  <h5>
                                    <a
                                      style={{
                                        color: "black",
                                        textDecoration: "none",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => readNotifications(item._id, "order")}
                                    >
                                      {item?.message}{" "}
                                      <span className="dotNotific">
                                        {item.isRead == false && (
                                          <i className="fa-solid fa-circle"></i>
                                        )}
                                      </span>
                                    </a>
                                  </h5>
                                ) : item.status == "service" ? (
                                  <h5>
                                    <a
                                      style={{
                                        color: "black",
                                        textDecoration: "none",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => readNotifications(item._id, "service")}
                                    >
                                      {item?.message}{" "}
                                      <span className="dotNotific">
                                        {item.isRead == false && (
                                          <i className="fa-solid fa-circle"></i>
                                        )}
                                      </span>
                                    </a>
                                  </h5>
                                ) : item.status == "payment" ? (
                                  <h5>
                                    <a
                                      href={item?.message}
                                      style={{
                                        color: "black",
                                        textDecoration: "none",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => readNotifications(item._id, "payment")}
                                    >
                                      Appointment Complete Proceed to Pay{" "}
                                      <span className="dotNotific">
                                        {item.isRead == false && (
                                          <i className="fa-solid fa-circle"></i>
                                        )}
                                      </span>
                                    </a>
                                  </h5>
                                ) : (
                                  <h5>
                                    <a
                                      style={{
                                        color: "black",
                                        textDecoration: "none",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => readNotifications(item._id, "chat")}
                                    >
                                      {item?.message}{" "}
                                      <span className="dotNotific">
                                        {item.isRead == false && (
                                          <i className="fa-solid fa-circle"></i>
                                        )}
                                      </span>
                                    </a>
                                  </h5>
                                )}
                                <p>{timeChangeCurrent(item.createdAt)}</p>
                              </div>
                            </div>
                          ))}
                        

                        </>
                      ) : (
                        <h6 className="free_contant">There is no Notification!</h6>
                      )}
                    </ul>

                  </div>
                </li>
                {user?.role === "user" && (
                  <li>
                    <Link style={{ color: "#edac3e" }} to="/add_to_cart">
                      <Badge badgeContent={cart} color="primary">
                        <ShoppingBag color="action" />
                      </Badge>
                    </Link>
                  </li>
                )}
                <li>
                  <div className="dropdown">
                    <button
                      className="btn btn-secondary position-relative dropdown-toggle Profile_sign_btn"
                      style={{
                        width: 120,
                      }}
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      &nbsp; Profile
                    </button>
                    <img
                      src={user?.profileImage || dummyImage}
                      className="position-absolute"
                      alt=""
                      style={{
                        width: 25,
                        height: 25,
                        left: 8,
                        top: 3,
                        borderRadius: 20,
                        objectFit: "cover",
                      }}
                    />

                    <ul
                      className="dropdown-menu add_drop"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li>
                        <Link className="dropdown-item" to="/my_account">
                          My Account
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/live_chat">
                          Chat
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item"
                          to={
                            token && user?.role === "user"
                              ? "/appointments"
                              : "/my_appointments"
                          }
                        >
                          My Appointments
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/my_order">
                          My Orders
                        </Link>
                      </li>
                      {user?.role === "user" ? (
                        <li>
                          <Link className="dropdown-item" to="/favorite">
                            Favorite
                          </Link>
                        </li>
                      ) : null}
                      {/* <li><Link className="dropdown-item" to="/add_to_cart">Cart</Link></li> */}
                      {user?.role === "business" ? (
                        <li>
                          <Link className="dropdown-item" to="/earning">
                            Earning
                          </Link>
                        </li>
                      ) : null}
                      {user?.role === "business" ? (
                        <li>
                          <Link
                            className="dropdown-item"
                            to="/business_setup"
                          >
                            Set Up My Business
                          </Link>
                        </li>
                      ) : null}
                      {user?.role === "business" ? (
                        <li>
                          <Link
                            className="dropdown-item"
                            onClick={() => switchRoleFun("user")}
                          >
                            Switch to User
                          </Link>
                        </li>
                      ) : (
                        <li>
                          <Link
                            className="dropdown-item"
                            onClick={() => switchRoleFun("business")}
                          >
                            Switch to Business
                          </Link>
                        </li>
                      )}
                      <li>
                        <button
                          type="button"
                          className="view_more_btn12"
                          onClick={logoutFun}
                        >
                          Logout
                        </button>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            ) : (
              <ul className="navbar_list">
                <li>
                  <Link
                    to="/business/create_business_account"
                    className="login_btn"
                  >
                    Set Up My Business
                  </Link>
                </li>
                <li>
                  <Link to="/user/login" className="login_btn">
                    Log In
                  </Link>
                </li>
                <li>
                  <Link to="/user/signup" className="login_btn">
                    Sign Up
                  </Link>
                </li>
                <li className="help1">
                  <Link to="/help" className="login_btn">
                    Help
                  </Link>
                </li>
              </ul>
            )}
          </form>
        </div>
      </div>
    </nav>
  );
}

export default NavbarHome;
