import { useTheme } from "@mui/material";
import ReactEcharts from "echarts-for-react";

const ComparisonChart = ({ type, chartData, height, color = [] }) => {
  const theme = useTheme();
  let option = null;

  if (type == "week") {
    option = {
      grid: { top: "10%", bottom: "10%", right: "1%" },
      legend: { show: false },
      color: ["#EDAC3E", "#FFD287"],
      barGap: 0,
      barMaxWidth: "64px",
      tooltip: {
        trigger: 'axis', // Show tooltip when hovering over bars
        axisPointer: {
          type: 'shadow' // Display the tooltip as a shadow on the bars
        }
      },
      dataset: {
        source: [
          ["Month", "Earning", "App"],
          ["Sun", chartData?.Sunday],
          ["Mon", chartData?.Monday],
          ["Tue", chartData?.Tuesday],
          ["Wed", chartData?.Wednesday],
          ["Thu", chartData?.Thursday],
          ["Fri", chartData?.Friday],
          ["Sat", chartData?.Saturday],
        ],
      },
      xAxis: {
        type: "category",
        axisLine: { show: false },
        splitLine: { show: false },
        axisTick: { show: false },
        axisLabel: {
          fontSize: 13,
          fontFamily: "roboto",
          color: theme.palette.text.secondary,
          show: true,
        },
      },
      yAxis: {
        axisLine: { show: false },
        axisTick: { show: false },
        splitLine: {
          lineStyle: { color: theme.palette.text.secondary, opacity: 0.15 },
        },
        axisLabel: {
          fontSize: 13,
          fontFamily: "roboto",
          color: theme.palette.text.secondary,
          show: true,
        },
      },
      series: [{ type: "bar" }],
    };
  } else if (type == "month") {
    option = {
      grid: { top: "10%", bottom: "10%", right: "1%" },
      legend: { show: false },
      color: ["#EDAC3E", "#FFD287"],
      barGap: 0,
      barMaxWidth: "64px",
      tooltip: {
        trigger: 'axis', // Show tooltip when hovering over bars
        axisPointer: {
          type: 'shadow' // Display the tooltip as a shadow on the bars
        }
      },
      dataset: {
        source: [
          ["Month", "Earning", "App"],
          ["Jun", chartData?.January],
          ["Feb", chartData?.February],
          ["Mar", chartData?.March],
          ["Apr", chartData?.April],
          ["May", chartData?.May],
          ["Jun", chartData?.June],
          ["Jul", chartData?.July],
          ["Aug", chartData?.August],
          ["Sep", chartData?.September],
          ["Oct", chartData?.October],
          ["Nov", chartData?.November],
          ["Dec", chartData?.December],
        ],
      },
      xAxis: {
        type: "category",
        axisLine: { show: false },
        splitLine: { show: false },
        axisTick: { show: false },
        axisLabel: {
          fontSize: 13,
          fontFamily: "roboto",
          color: theme.palette.text.secondary,
        },
      },
      yAxis: {
        axisLine: { show: false },
        axisTick: { show: false },
        splitLine: {
          lineStyle: { color: theme.palette.text.secondary, opacity: 0.15 },
        },
        axisLabel: {
          fontSize: 13,
          fontFamily: "roboto",
          color: theme.palette.text.secondary,
        },
      },
      series: [{ type: "bar" }],
    };
  } else {
    option = {
      grid: { top: "10%", bottom: "10%", right: "1%" },
      legend: { show: false },
      color: ["#EDAC3E", "#FFD287"],
      barGap: 0,
      barMaxWidth: "64px",
      tooltip: {
        trigger: 'axis', // Show tooltip when hovering over bars
        axisPointer: {
          type: 'shadow' // Display the tooltip as a shadow on the bars
        }
      },
      dataset: {
        source: [
          ["Month", "Earning", "App"],
          // ...chartData.year.map(item => [item.yearName, item.value])
        ]



        // source: [
        //   ["Month", "Earning", "App"],
        //   [chartData?.yearName, chartData?.value],
        // ],
      },
      xAxis: {
        type: "category",
        axisLine: { show: false },
        splitLine: { show: false },
        axisTick: { show: false },
        axisLabel: {
          fontSize: 13,
          fontFamily: "roboto",
          color: theme.palette.text.secondary,
        },
      },
      yAxis: {
        axisLine: { show: false },
        axisTick: { show: false },
        splitLine: {
          lineStyle: { color: theme.palette.text.secondary, opacity: 0.15 },
        },
        axisLabel: {
          fontSize: 13,
          fontFamily: "roboto",
          color: theme.palette.text.secondary,
        },
      },
      series: [{ type: "bar" }],
    };
  }

  return <ReactEcharts style={{ height: height }} option={{ ...option }} />;
};

export default ComparisonChart;
