import React from 'react';
import './loader.css';

const Loader = () => {
  return (
    <div className="loader">
      <div className="loader-content">
        <div className="spinner"></div>
       
        <div className="dots">
          <span></span>
          <span></span>
          <span></span>
        </div>

      </div>
    </div>
  );
}

export default Loader;
